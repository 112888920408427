import React from 'react';
import Login from '../icons/Login';

function OurOnlineCourses() {
  return (
    <a href="http://online.saikt.ru/" target="_blank" rel="noopener noreferrer" className="online-courses">
      <h3 className="online-courses__title">Наши онлайн-курсы</h3>
      {Login}
    </a>
  );
}

export default OurOnlineCourses;
