import React, { useState } from 'react';
import AntonAgafonov from '../../assets/images/lecturers/AntonAgafonov.jpg';
import MaximBobrovskiy from '../../assets/images/lecturers/MaximBobrovskiy.jpg';
import VladimirKnyazev from '../../assets/images/lecturers/VladimirKnyazev.jpg';
import EvgenyStoyan from '../../assets/images/lecturers/EvgenyStoyan.jpg';
import GlobalPortal from '../ui/GlobalPortal';
import LecturerPopup from './popups/LecturerPopup';

export interface Lecturer {
  id: number;
  icon: any;
  name: {
    firstName: string;
    lastName: string;
  };
  experience: string;
  education: string;
  courses: Array<string>;
  description: string;
  hobbies: string;
}

function Lecturers() {
  const lecturers = [
    {
      id: 1,
      icon: AntonAgafonov,
      name: {
        firstName: 'Антон',
        lastName: 'Агафонов',
      },
      experience: '9 лет',
      education: 'ОмГПУ, высшее',
      courses: ['Пользователь ПК', 'Web-дизайн', 'Разработка игр в среде Unity 3D'],
      description:
        'Занимается разработкой электронных дистанционных систем обучения, а ' +
        'также интерактивных курсов по различным дисциплинам. ' +
        'В портфолио Антона - проекты на различных игровых движках, от Construct2 ' +
        'до Unreal Engine. Основные направления еятельности - компьютерная ' +
        'анимация и web-разработка. ',
      hobbies: 'новинки программного обеспечения, научная фантастика, походы и рыбалка.',
    },
    {
      id: 2,
      icon: MaximBobrovskiy,
      name: {
        firstName: 'Максим',
        lastName: 'Бобровский',
      },
      experience: '19 лет',
      education: 'НГПУ, высшее',
      courses: ['Пользователь ПК', 'Web-программирование'],
      description:
        'Своей основной задачей считает поиск и развитие направлений для реализации ' +
        'студентов в области программирования: стажировки и трудоустройство, ' +
        'конкурсы и олимпиады, поступление в ведущие вузы России и зарубежных ' +
        'стран. ' +
        'Все занятия в Академии выстраивает по принципу 20% теории, 80% практики',
      hobbies: 'компьютерная лингвистика, искусственный интеллект и копирайтинг.',
    },
    {
      id: 3,
      icon: VladimirKnyazev,
      name: {
        firstName: 'Владимир',
        lastName: 'Князев',
      },
      experience: '10 лет',
      education: 'ОмГУПС, высшее',
      courses: ['Программирование на С/С++'],
      description:
        'Помимо преподавания, Владимир занимается коммерческой разработкой ' +
        'программных и игровых продуктов. В портфолио - системы виртуальной ' +
        'реальности, мобильный клиент для одного из банков Израиля, службы заказа ' +
        'такси и проката автомобилей, модули для работы с CAN-шиной автомобиля. ' +
        'На занятиях разбирает реальные кейсы и учит студентов мыслить креативно.',
      hobbies: 'изучение английского языка, рок-музыка и автомобили.',
    },
    {
      id: 4,
      icon: EvgenyStoyan,
      name: {
        firstName: 'Евгений',
        lastName: 'Стоян',
      },
      experience: '5 лет',
      education: 'ОмГТУ, высшее',
      courses: ['Пользователь ПК', 'Web-программирование', 'Программирование на Python '],
      description:
        'Кроме активной преподавательской деятельности, Евгений занимается ' +
        'разработкой сайтов и игр. В портфолио - сайты для ресторанов Омска, 2D и ' +
        '3D игры, web-приложение для учебных центров. ' +
        'Уделяет большое внимание содержанию и постоянной актуализации курсов ' +
        'Академии.',
      hobbies: 'участие в международных конкурсах по программированию, путешествия и спорт',
    },
  ];

  const [lecture, toggleLecture] = useState<Lecturer>(lecturers[0]);
  const [isOpenLecture, toggleOpeningLecture] = useState(false);

  const openLecture = (lecture: Lecturer) => {
    toggleOpeningLecture(true);
    toggleLecture(lecture);
  };

  const renderCourses = (courses: Array<string>) => {
    return courses.map((course) => {
      return (
        <div key={course} className="lecturer-course">
          {course}
        </div>
      );
    });
  };

  const renderLecturers = () => {
    return lecturers.map((lecture: Lecturer) => {
      const {
        icon,
        name: { firstName, lastName },
        experience,
        education,
        courses,
      } = lecture;
      return (
        <div key={firstName + lastName} className="lecturer">
          <div className="lecturer-wrapper">
            <img className="lecturer__icon" src={icon} alt="lecture" />
            <div className="lecturer__name-block">
              <h3 className="lecturer__name">{firstName}</h3>
              <h3 className="lecturer__name">{lastName}</h3>
            </div>
            <p className="lecturer__text">Преподавательский стаж</p>
            <p className="lecturer__experience">{experience}</p>
            <p className="lecturer__text">Образование</p>
            <p className="lecturer__education">{education}</p>
            <p className="lecturer__text">Преподает на курсах</p>
            <div className="lecturer-courses">{renderCourses(courses)}</div>
          </div>
          <div className="lecturer-wrapper">
            <p className="lecturer__more-info" onClick={() => openLecture(lecture)}>
              Больше информации
            </p>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="lecturers">
      {renderLecturers()}
      {isOpenLecture && (
        <GlobalPortal close={() => toggleOpeningLecture(false)}>
          <LecturerPopup lecture={lecture} close={() => toggleOpeningLecture(false)} renderCourses={renderCourses} />
        </GlobalPortal>
      )}
    </div>
  );
}

export default Lecturers;
