import React, { useEffect, useState } from 'react';
import Header from '../shared/Header';
import Navbar from '../shared/Navbar';
import Banner from './Banner';
import ContainerPage from '../ui/ContainerPage';
import OurCourses from './OurCourses';
import OurOnlineCourses from './OurOnlineCourses';
import CourseHelp from './CourseHelp';
import WhyWeAre from './WhyWeAre';
import Consultation from './Consultation';
import Lecturers from './Lecturers';
import Reviews from './Reviews';
import AcademyLife from './AcademyLife';
import Footer from '../shared/Footer';
import NeedCall from '../shared/NeedCall';

export interface Link {
  name: string;
  to: string;
}

function Main() {
  const links = [
    { name: 'Курсы', to: 'courses' },
    { name: 'О нас', to: 'aboutUs' },
    { name: 'Преподаватели', to: 'lecturers' },
    { name: 'Отзывы', to: 'reviews' },
    { name: 'Контакты', to: 'contacts' },
  ];

  const cities = [
    {
      name: 'Новосибирск',
      phones: ['+7 913 377 89 35'],
      address: [
        {
          title: 'ул. Федосеева, д. 38, каб. 321',
          type: '',
        },
        {
          title: '(здание гимназии №11)',
          type: 'gray',
        },
      ],
    },
    {
      name: 'Омск',
      phones: ['+7 (3812) 51 89 35', '+7 913 651 89 35'],
      address: [
        {
          title: 'ул. 5-я Линия, д. 157-А, офис 236',
          type: '',
        },
        {
          title: 'ул. Степанца, д. 10/5, офис 412',
          type: '',
        },
      ],
    },
  ];

  const [currentCity, toggleCurrentCity] = useState(cities[0]);
  const [headerHeight, toggleHeaderHeight] = useState(122);

  const getHeaderHeight = () => {
    const header = document.getElementById('header');
    if (header) {
      const { height } = header.getBoundingClientRect();
      toggleHeaderHeight(height);
    }
  };

  useEffect(() => {
    getHeaderHeight();
    window.addEventListener('resize', getHeaderHeight);
  });

  return (
    <div style={{ marginTop: headerHeight }} className="home">
      <Header links={links} cities={cities} currentCity={currentCity} toggleCurrentCity={toggleCurrentCity} />
      <Navbar links={links} />
      <Banner />
      <ContainerPage title="Наши курсы" id="courses">
        <OurCourses />
        <OurOnlineCourses />
      </ContainerPage>
      <CourseHelp />
      <ContainerPage title="Почему мы?" id="aboutUs" className="why-we-are-wrapper">
        <WhyWeAre />
      </ContainerPage>
      <Consultation
        theme="light"
        titles={['Получи консультацию', 'по курсам и обучению']}
        subtitle={'прямо сейчас!'}
        ymTarget={() => {
          //@ts-ignore
          window.ym(67016755, 'reachGoal', 'consultationForm');
        }}
      />
      <ContainerPage title="Наши преподаватели" id="lecturers">
        <Lecturers />
      </ContainerPage>
      <ContainerPage title="Отзывы о курсах" id="reviews">
        <Reviews />
      </ContainerPage>
      <ContainerPage title="Немного из жизни Академии" className="academy-life-wrapper">
        <AcademyLife />
      </ContainerPage>
      <Consultation
        theme="dark"
        titles={['Оставьте заявку и мы', 'свяжемся с вами']}
        subtitle={'в ближайшее время!'}
        id="contacts"
        ymTarget={() => {
          //@ts-ignore
          window.ym(67016755, 'reachGoal', 'requestForm');
        }}
      />
      <Footer currentCity={currentCity} />
      <NeedCall />
    </div>
  );
}

export default Main;
