import React from 'react';
import LogoLight from './LogoLight';
import LogoMobile from './LogoMobile';
import CitySelect from './CitySelect';
import CityList from './CityList';
import BurgerMenu from './BurgerMenu';
import { Link } from '../home/Main';

type Props = {
  links: Array<Link>;
  cities: any;
  currentCity: any;
  toggleCurrentCity: any;
};

function Header(props: Props) {
  const { links, cities, currentCity, toggleCurrentCity } = props;

  return (
    <header id="header" className="header">
      <div className="header-block-logo">
        <LogoMobile />
        <LogoLight />
      </div>
      <div className="header-block">
        <p className="header-block-text">
          Сибирская Академия инновационных
          <br />И компьютерных технологий
        </p>
      </div>
      <div className="header-block-city-select">
        <CitySelect cities={cities} currentCity={currentCity} toggleCurrentCity={toggleCurrentCity} />
      </div>
      <div className="header-block-city-list">
        <CityList currentCity={currentCity} />
      </div>
      <BurgerMenu links={links} />
    </header>
  );
}

export default Header;
