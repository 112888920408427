import React from 'react';

function Unity() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="151" height="150" fill="none">
      <path
        className="our-course__icon"
        d="M75.332 150c41.605 0 75.333-33.579 75.333-75S116.937 0 75.333 0C33.727 0 0 33.579 0 75s33.727 75 75.332 75z"
      />
      <path
        fill="#000"
        d="M16.012 98.75h118.654v3.256a7.274 7.274 0 01-2.145 5.155 7.337 7.337 0 01-5.177 2.135H23.322a7.34 7.34 0 01-5.178-2.135A7.273 7.273 0 0116 102.006v-3.255h.012z"
      />
      <path
        fill="#fff"
        d="M134.64 98.88H16V39.29a7.27 7.27 0 012.145-5.155A7.338 7.338 0 0123.322 32h104.009a7.34 7.34 0 015.178 2.135 7.274 7.274 0 012.144 5.155v59.59h-.013z"
      />
      <path fill="#E6E6E6" d="M134.64 98.893H16v-59.59l118.64 59.59z" />
      <path fill="#1B79B3" d="M130.21 36.968H20.703v57.761H130.21V36.968z" />
      <path
        fill="#fff"
        d="M79.681 65.456l7.89-13.564 3.814 13.564-3.813 13.56-7.89-13.56zm-3.846 2.203l7.892 13.562-13.744-3.506-9.93-10.056h15.782zm7.89-17.972l-7.89 13.564H60.054l9.929-10.057 13.742-3.507zM94.99 60.84l-4.814-17.83-17.968 4.78-2.66 4.655-5.397-.038L51 65.457l13.151 13.048 5.396-.04 2.663 4.656 17.966 4.777 4.814-17.825-2.734-4.617 2.734-4.616zm-5.652 48.456H61.314v11.843h28.024v-11.843z"
      />
      <path fill="#C7C7C7" d="M89.338 109.296H61.314v5.202h28.024v-5.202z" />
      <path
        fill="#fff"
        d="M110.289 126.859H40.637c0-1.503.6-2.945 1.667-4.008a5.707 5.707 0 014.026-1.66h58.265a5.713 5.713 0 014.026 1.66 5.653 5.653 0 011.668 4.008z"
      />
    </svg>
  );
}

export default Unity();
