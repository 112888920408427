import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  children: any;
  close: any;
}

const GlobalPortal = ({ children, close }: Props) => {
  const element = document.createElement('div');
  const { body } = document;
  const $selector = 'global-portal';

  const closePortal = (e: any) => {
    const { target, keyCode } = e;

    if (!target.closest(`div:not(.${$selector})`)) {
      close && close(target);
    }

    if (keyCode === 27) {
      close && close();
    }
  };

  useEffect(() => {
    body.appendChild(element);
    element.className = $selector;
    document.addEventListener('mousedown', closePortal);
    document.addEventListener('keyup', closePortal);

    return function cleanUp() {
      body.removeChild(element);
      document.removeEventListener('mousedown', closePortal);
      document.removeEventListener('keyup', closePortal);
    };
  });

  return createPortal(<div className={`${$selector}__container`}>{children}</div>, element);
};

export default GlobalPortal;
