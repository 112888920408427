import React, { Component } from 'react';

type SwitcherProps = {
  close: () => void;
  children?: any;
};

class GlobalModal extends Component<SwitcherProps> {
  private wrapper: any;

  constructor(props: SwitcherProps) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.closePopup);
    document.addEventListener('keyup', this.closePopup);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.closePopup);
    document.removeEventListener('keyup', this.closePopup);
  }

  setWrapperRef = (node: any) => {
    this.wrapper = node;
  };

  closePopup = (event: any) => {
    const { close } = this.props;
    if (!close) return;
    if (this.wrapper && !this.wrapper.contains(event.target) && !event.keyCode) {
      close();
    }
    if (this.wrapper && event.keyCode === 27) {
      close();
    }
  };

  render() {
    const { children } = this.props;
    return (
      children && (
        <div className="global-modal" ref={this.setWrapperRef}>
          {children}
        </div>
      )
    );
  }
}

export default GlobalModal;
