import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
// @ts-ignore
import loadable from '@loadable/component';
import { PrerenderedComponent } from 'react-prerendered-component';

const prerenderedLoadable = (dynamicImport: any) => {
  const LoadableComponent = loadable(dynamicImport);
  return React.memo((props) => (
    <PrerenderedComponent live={LoadableComponent.load()}>
      <LoadableComponent {...props} />
    </PrerenderedComponent>
  ));
};

const rootElement = document.getElementById('root');
if (rootElement && rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    // @ts-ignore
    prerenderedLoadable(() => <App />),
    rootElement
  );
} else {
  ReactDOM.render(<App />, rootElement);
}
