import React, { useState } from 'react';
import Input from '../../ui/Input';
import Textarea from '../../ui/Textarea';

type Props = {
  close: any;
};

function FeedbackPopup(props: Props) {
  const { close } = props;

  const [name, toggleName] = useState('');
  const [nameError, toggleNameError] = useState('');
  const [review, toggleReview] = useState('');
  const [reviewError, toggleReviewError] = useState('');
  const [rating, toggleRating] = useState(5);

  const validateInput = () => {
    let error = false;

    if (!name) {
      toggleNameError('Введите имя');
      error = true;
    } else {
      toggleNameError('');
      error = false;
    }

    return error;
  };

  const validateReview = () => {
    let error = false;

    if (!review) {
      toggleReviewError('Это обязательное поле');
      error = true;
    } else {
      toggleReviewError('');
      error = false;
    }

    return error;
  };

  const validate = () => {
    return validateInput() && validateReview();
  };

  const submit = () => {
    if (validate()) return;
  };

  const renderRating = () => {
    let ratingArr = [];
    for (let i = 1; i <= 5; i++) {
      const className =
        i <= rating
          ? 'feedback-popup-body-rating__icon feedback-popup-body-rating__active'
          : 'feedback-popup-body-rating__icon';
      ratingArr.push(
        <i key={className + i} className={`fas fa-user-graduate ${className}`} onClick={() => toggleRating(i)} />
      );
    }
    return ratingArr;
  };

  return (
    <div className="feedback-popup">
      <i className="far fa-2x fa-times-circle global-portal__close" onClick={close} />
      <div className="feedback-popup-header">
        <h3 className="feedback-popup__title">Ваш отзыв</h3>
      </div>
      <div className="feedback-popup-body">
        <div className="feedback-popup-body__form">
          <Input
            value={name}
            placeholder="Имя"
            onChange={(event: any) => toggleName(event.target.value)}
            error={nameError}
          />
          <Textarea
            value={name}
            className="feedback-popup-body-wrapper"
            placeholder="Текст"
            onChange={(event: any) => toggleReview(event.target.value)}
            error={reviewError}
          />
        </div>
        <div className="feedback-popup-body__photo">
          <div className="feedback-popup-body-upload feedback-popup-body-upload__plus">
            <i className="fas fa-plus" />
            <p className="feedback-popup-body-upload__text">Загрузите фото</p>
          </div>
          <div className="feedback-popup-body-rating">{renderRating()}</div>
        </div>
      </div>
      <div className="feedback-popup-buttons">
        <button type="button" className="main-button" onClick={submit}>
          Отправить
        </button>
      </div>
    </div>
  );
}

export default FeedbackPopup;
