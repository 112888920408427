import React, { useState } from 'react';
import PolinaPerepelica from '../../assets/images/reviewers/PolinaPerepelica.png';
import DenisKirov from '../../assets/images/reviewers/DenisKirov.png';
import VladimirDavidoff from '../../assets/images/reviewers/VladimirDavidoff.png';
import VeraNikolaeva from '../../assets/images/reviewers/VeraNikolaeva.png';
import GlobalPortal from '../ui/GlobalPortal';
import FeedbackPopup from './popups/FeedbackPopup';
import ReadMore from '../ui/ReadMore';

interface Review {
  icon: any;
  name: string;
  social: string;
  rating: number;
  text: string;
}

function Reviews() {
  const reviews = [
    {
      icon: PolinaPerepelica,
      name: 'Полина Перепелица',
      social: 'В Контакте',
      rating: 4,
      text:
        'Дочь пришла учиться на курс веб-дизайна, но попала на бесплатное занятие по Python, там и осталась. Никто и подумать не мог, что у нее есть тяга к программированию. Так бы и не узнали, наверное, если бы не преподаватели «САИКТ», которые настолько заинтересовали предметом, что мы оставили в стороне прошлые увлечения и уже записались на продвинутый курс по программированию.',
    },
    {
      icon: DenisKirov,
      name: 'Денис Киров',
      social: 'Instagram',
      rating: 5,
      text:
        'Хожу сюда на Web-программирование. Преподаватели - крутые, все очень доступно объясняют. Старший брат учился на С++ здесь же - сейчас в вузе учится, говорит, что это просто небо и земля - в САИКТЕ узнал по программированию столько, сколько в вузе никогда не расскажут. В общем, очень круто, записывайтесь, не пожалеете. Кстати, мне скидку на обучение сделали 20%, так как брат здесь учился :-)',
    },
    {
      icon: VladimirDavidoff,
      name: 'Владимир Давыдов',
      social: 'В Контакте',
      rating: 4,
      text:
        'Учился здесь на курсе Программирование на Python. В момент поступления, честно сказать вообще ничего не знал об этом языке программирования даже приблизительно. Считаю, что для начинающего эти курсы оптимальный вариант. Мне стало все ясно и уже начал самостоятельно создавать собственные приложения и программы на Пайтоне. Преподаватель никогда не откажет в дополнительном разъяснении или совете, если что-то осталось непонятным.',
    },
    {
      icon: VeraNikolaeva,
      name: 'Вера Николаева',
      social: 'Instagram',
      rating: 5,
      text:
        'У меня сын (13 лет) ходит сюда первый год. Ну как ходит - бежит)). Нет, серьезно, посещаем несколько кружков разных (английский и пр.), но никуда он не ходит с ТАКИМ удовольствием, как в Академию «САИКТ». И главное - результат есть. Сын ходит на курс программирования Питон, так недавно принес игру, которую сделал сам. Спрашиваю: «Прямо сам и написал?». Ну да, говорит, с преподавателем все обсудили, он немного помогает, но все сами программируем. В общем, я очень довольна.',
    },
  ];

  const [isOpenFeedback, toggleOpeningFeedback] = useState(false);

  const renderRating = (rating: number) => {
    let ratingArr = [];
    for (let i = 1; i <= 5; i++) {
      const className = i <= rating ? 'review-header__icon review-header__icon_active' : 'review-header__icon';
      ratingArr.push(<i key={className + i} className={`fas fa-user-graduate ${className}`} />);
    }
    return ratingArr;
  };

  const renderReviews = () => {
    return reviews.map((review: Review) => {
      const { icon, name, rating, text } = review;
      let outputText = [];
      if (text.length >= 400) {
        outputText.push(<ReadMore key={text} text={text} />);
      } else {
        outputText.push(
          <div key={text} className="review-footer">
            <p className="review-footer__text">{text}</p>
          </div>
        );
      }
      return (
        <div key={name} className="review">
          <div className="review-header">
            <img src={icon} alt="reviewer" />
            <h3 className="review-header__name">{name}</h3>
            <div className="review-header-rating">{renderRating(rating)}</div>
          </div>
          {outputText}
        </div>
      );
    });
  };

  return (
    <div className="reviews">
      {renderReviews()}
      <div className="reviews-buttons">
        <button className="main-button" type="button" onClick={() => toggleOpeningFeedback(true)}>
          Оставить отзыв
        </button>
        <button className="main-button-white" type="button">
          Читать еще
        </button>
      </div>
      {isOpenFeedback && (
        <GlobalPortal close={() => toggleOpeningFeedback(false)}>
          <FeedbackPopup close={() => toggleOpeningFeedback(false)} />
        </GlobalPortal>
      )}
    </div>
  );
}

export default Reviews;
