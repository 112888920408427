import React from 'react';

type Props = {
  title: string;
  children?: any;
  className?: any;
  id?: any;
};

function ContainerPage(props: Props) {
  const { title, children, className = '', id = '' } = props;
  return (
    <div id={id} className={`container-page ${className}`}>
      <h2 className="container-page__title">{title}</h2>
      {children}
    </div>
  );
}

export default ContainerPage;
