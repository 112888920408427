import { get, map } from 'lodash';

export default function parseErrorsAPI(response: any) {
  let error = '';
  const errors = get(response, 'errors', {});
  map(errors, (errorsArr) => {
    map(errorsArr, (errorArr) => (error += errorArr));
  });
  if (!error) return get(response, 'message', '');
  return error;
}
