import React from 'react';
import BitcomLogo from '../icons/BitcomLogo';

type Props = {
  className?: string;
};

export default function BitcomSolution(props: Props) {
  const { className = '' } = props;
  return (
    <a
      href="https://bitcomsolution.com/"
      className={`bitcom-solution ${className}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <p className="bitcom-solution__text"></p>
      
    </a>
  );
}
