import React from 'react';

function C() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="151" height="150" fill="none" viewBox="0 0 151 150">
      <path
        className="our-course__icon"
        d="M75.332 150c41.605 0 75.333-33.579 75.333-75S116.937 0 75.333 0C33.727 0 0 33.579 0 75s33.727 75 75.332 75z"
      />
      <path
        fill="#000"
        d="M16.012 98.75h118.654v3.256a7.274 7.274 0 01-2.145 5.155 7.337 7.337 0 01-5.177 2.135H23.322a7.34 7.34 0 01-5.178-2.135A7.273 7.273 0 0116 102.006v-3.255h.012z"
      />
      <path
        fill="#fff"
        d="M134.64 98.88H16V39.29c0-1.934.771-3.788 2.145-5.155A7.338 7.338 0 0123.322 32h104.009a7.34 7.34 0 015.178 2.135 7.274 7.274 0 012.144 5.155v59.59h-.013z"
      />
      <path fill="#E6E6E6" d="M134.64 98.893H16v-59.59l118.64 59.59z" />
      <path fill="#1B79B3" d="M130.21 36.968H20.703v57.761H130.21V36.968z" />
      <path fill="#fff" d="M89.338 109.296H61.314v11.843h28.024v-11.843z" />
      <path fill="#C7C7C7" d="M89.338 109.296H61.314v5.202h28.024v-5.202z" />
      <path
        fill="#fff"
        d="M110.289 126.859H40.637c0-1.503.6-2.945 1.667-4.008a5.707 5.707 0 014.026-1.66h58.265a5.713 5.713 0 014.026 1.66 5.653 5.653 0 011.668 4.008zM95.656 45H56.344c-.742 0-1.344.602-1.344 1.344v39.312c0 .742.602 1.344 1.344 1.344h39.312c.742 0 1.344-.602 1.344-1.344V46.344c0-.742-.602-1.344-1.344-1.344zM71.398 70.974a6.96 6.96 0 01-4.582 1.728c-3.777 0-6.85-3.01-6.85-6.71 0-3.691 3.073-6.694 6.85-6.694 1.683 0 3.305.609 4.567 1.714a1.253 1.253 0 11-1.651 1.885 4.423 4.423 0 00-2.916-1.093c-2.396 0-4.345 1.878-4.345 4.188 0 2.318 1.95 4.204 4.345 4.204a4.456 4.456 0 002.932-1.108 1.253 1.253 0 111.65 1.886zm8.59-3.721h-2.525v2.525a1.253 1.253 0 01-2.506 0v-2.525h-2.525a1.253 1.253 0 110-2.506h2.525v-2.525a1.253 1.253 0 112.506 0v2.525h2.525a1.253 1.253 0 110 2.506zm10.793 0h-2.524v2.525a1.253 1.253 0 01-2.506 0v-2.525h-2.525a1.253 1.253 0 110-2.506h2.525v-2.525a1.253 1.253 0 112.506 0v2.525h2.524a1.253 1.253 0 110 2.506z"
      />
    </svg>
  );
}

export default C();
