import React from 'react';

function Design() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="159" height="152" fill="none">
      <path
        className="our-course__icon"
        d="M74.792 152c41.306 0 74.791-33.402 74.791-74.606 0-41.204-33.485-74.607-74.791-74.607C33.485 2.787 0 36.19 0 77.394S33.485 152 74.792 152z"
      />
      <path
        fill="#1B6E8C"
        d="M134.048 103.329H15.535V43.742c0-1.933.77-3.788 2.14-5.155a7.318 7.318 0 015.169-2.135h103.895c1.939 0 3.798.768 5.168 2.135a7.28 7.28 0 012.141 5.155v59.587z"
      />
      <path fill="#175E78" d="M134.048 103.368H15.535V43.729l118.513 59.639z" />
      <path
        fill="#071C24"
        d="M15.535 103.329h118.513v3.252a7.28 7.28 0 01-2.141 5.155 7.316 7.316 0 01-5.168 2.135H22.844a7.318 7.318 0 01-5.168-2.135 7.282 7.282 0 01-2.14-5.155v-3.252z"
      />
      <path fill="#73C9FF" d="M129.611 41.394H20.231V99.2h109.38V41.394z" />
      <path fill="#fff" d="M85.618 90.258H61.016V61.819h27.552v25.458l-2.95 2.981z" />
      <path fill="#ABABAB" d="M85.618 87.277v2.981l2.95-2.98h-2.95z" />
      <path
        fill="#3B3B3B"
        d="M73.227 83.497h-4.398V68.258h4.398a7.59 7.59 0 015.347 2.215 7.551 7.551 0 012.22 5.333v.142a7.551 7.551 0 01-2.22 5.334 7.59 7.59 0 01-5.347 2.215zm-3.765-.62h3.765a6.955 6.955 0 004.898-2.029 6.92 6.92 0 002.035-4.887v-.142a6.92 6.92 0 00-2.035-4.886 6.955 6.955 0 00-4.898-2.03h-3.765V82.89v-.013z"
      />
      <path fill="#526375" d="M80.625 63.742h-.31v24.916h.31V63.742z" />
      <path
        fill="#FF5E5E"
        d="M81.389 87.729h-1.837v1.832h1.837V87.73zm0-24.903h-1.837v1.832h1.837v-1.832zm-.919 14.271a1.06 1.06 0 100-2.116 1.06 1.06 0 00-1.06 1.058 1.06 1.06 0 001.06 1.058zm-11.331-7.458a1.06 1.06 0 001.06-1.058 1.06 1.06 0 00-1.06-1.059 1.06 1.06 0 00-1.06 1.058 1.06 1.06 0 001.06 1.059zm0 14.696a1.06 1.06 0 001.06-1.058 1.06 1.06 0 00-1.06-1.058 1.06 1.06 0 00-1.06 1.058 1.06 1.06 0 001.06 1.058z"
      />
      <path
        fill="#E6E6E6"
        d="M69.333 46.116l-1.85 1.845-1.85 1.846s3.415 2.322 2.587 5.832l3.532 2.942a11.112 11.112 0 018.55 2.658l.465-.465a11.053 11.053 0 01-2.664-8.529l-2.95-3.522c-3.479.8-5.82-2.607-5.82-2.607z"
      />
      <path fill="#4D4D4D" d="M74.118 54.382l-.165.164 6.54 6.524.165-.164-6.54-6.524z" />
      <path
        fill="#4D4D4D"
        d="M74.644 55.094a.85.85 0 000-1.204.855.855 0 00-1.208 0 .85.85 0 000 1.204.855.855 0 001.208 0z"
      />
      <path fill="#F2B509" d="M68.388 43.729l-5.2 5.187 1.954 1.38 4.618-4.619-1.372-1.948z" />
      <path
        fill="#fff"
        d="M26.892 47.768h-3.919v4.297h3.92v-4.297zm0 6.258h-3.919v4.297h3.92v-4.297zm0 6.245h-3.919v4.297h3.92V60.27zm0 6.245h-3.919v4.297h3.92v-4.297z"
      />
      <path fill="#9190AD" d="M129.611 93.213H20.231V99.2h109.38v-5.987z" />
      <path
        fill="#2C5391"
        d="M23.62 98.426a2.222 2.222 0 002.225-2.22 2.222 2.222 0 00-2.225-2.219 2.222 2.222 0 00-2.225 2.22c0 1.225.996 2.219 2.225 2.219z"
      />
      <path fill="#fff" d="M129.611 99.2L20.231 41.394h109.38V99.2z" opacity="0.1" />
      <path fill="#1B6E8C" d="M88.787 113.923H60.795v11.845h27.992v-11.845z" />
      <path fill="#175E78" d="M88.787 113.923H60.795v5.2h27.992v-5.2z" />
      <path
        fill="#0B4B5C"
        d="M109.717 131.497H40.138c0-1.506.6-2.95 1.667-4.015a5.697 5.697 0 014.025-1.663h58.208c.748 0 1.488.147 2.178.433a5.691 5.691 0 013.081 3.072c.286.689.433 1.427.433 2.173h-.013z"
      />
      <path fill="#fff" d="M43.023 52.31h-8.227v35.974h8.227V52.31z" />
      <path fill="#575757" d="M35.623 54.723v4.284h6.585v-4.284h-6.584zm3.299 3.87h-2.936v-3.341h2.923v3.342h.013z" />
      <path
        fill="#F2B509"
        d="M43.023 52.348h-8.227v-1.845a1.76 1.76 0 011.759-1.755h4.709a1.763 1.763 0 011.625 1.084c.088.212.134.44.134.671v1.845z"
      />
      <path
        fill="#575757"
        d="M35.623 62.039v4.284h6.585v-4.284h-6.584zM37.5 66h-1.59v-1.38h1.59v1.457V66zm0-2.168h-1.59v-1.458h1.59v1.458zM39.711 66H38.12v-1.38h1.591v1.457V66zm0-2.168H38.12v-1.458h1.591v1.458zM41.988 66h-1.604v-1.38h1.59v1.457l.014-.077zm0-2.168h-1.604v-1.458h1.59v1.458h.014zm-6.365 13.097v-.697c2.264 0 2.588-1.393 2.95-3.02.362-1.625.802-3.547 3.635-3.547v.645c-2.303 0-2.587 1.406-3.001 3.045-.414 1.639-.802 3.574-3.584 3.574zm2.782 3.174l-1.785 5.239h.66l.543-1.652h1.862l.556 1.652h.725l-1.746-5.239h-.815zm-.401 3.058l.517-1.51c.104-.322.181-.632.259-.941.077.297.155.62.271.955l.518 1.51H37.99l.013-.014zm3.001-3.871v6.633h.207V79.29h-.207z"
      />
      <path
        fill="#238EB5"
        d="M21.408 37.845h106.25c1.327 0 2.601.526 3.54 1.463a4.99 4.99 0 011.466 3.53v58.556l-.738-57.884a4.987 4.987 0 00-1.474-3.45 5.015 5.015 0 00-3.467-1.454l-105.577-.76z"
      />
      <path
        fill="#134C61"
        d="M16.738 102.684c.272 0 .492-12.663.492-28.284 0-15.62-.22-28.284-.492-28.284-.271 0-.491 12.663-.491 28.284 0 15.62.22 28.284.491 28.284z"
      />
      <path fill="#EBEBEB" d="M152.17 76.323a.35.35 0 01-.349.348h-1.423v-.697h1.423a.35.35 0 01.349.348z" />
      <path fill="#B8B8B8" d="M151.29 76.67l-.892-.012.013-.697h.879v.71z" />
      <path
        fill="#404040"
        d="M150.864 76.232a1.295 1.295 0 01-1.294 1.29h-31.406a1.287 1.287 0 01-.915-2.203 1.3 1.3 0 01.915-.377h31.381a1.298 1.298 0 011.218.79c.067.159.101.329.101.5z"
      />
      <path
        fill="#242424"
        d="M150.864 76.232a1.295 1.295 0 01-1.294 1.29h-31.406a1.287 1.287 0 01-1.294-1.29h33.994z"
      />
      <path
        fill="#EBEBEB"
        d="M144.345 76.232a.622.622 0 01-.699.349h-2.962a.622.622 0 01-.699-.349.622.622 0 01.699-.348h2.962a.622.622 0 01.699.348zm-25.095 1.303h-.259v.93l1.785.012 9.184-.348v-.22l-9.184-.038v-.335h-1.086l.013-2.671h-.44l-.013 2.67z"
      />
      <path
        fill="#B5B5B5"
        d="M138.588 75.11c6.054 0 10.956 0 10.956.064 0 .065-4.902.194-10.956.194-6.053 0-18.523-.155-18.523-.194 0-.039 12.47-.064 18.523-.064z"
      />
      <path
        fill="#404040"
        d="M156.413 80.826h-44.717a2.57 2.57 0 00-2.574 2.567v36a2.57 2.57 0 002.574 2.568h44.717a2.57 2.57 0 002.574-2.568v-36a2.571 2.571 0 00-2.574-2.567z"
      />
      <path
        fill="#333"
        d="M156.387 121.987H111.67a2.59 2.59 0 01-1.829-.756 2.578 2.578 0 01-.758-1.825v-36c0-.684.273-1.34.758-1.825a2.59 2.59 0 011.829-.755l47.253 38.606a2.588 2.588 0 01-2.536 2.555z"
      />
      <path
        fill="#171717"
        d="M134.048 120.619a4.483 4.483 0 004.489-4.477 4.483 4.483 0 00-4.489-4.477 4.482 4.482 0 00-4.488 4.477 4.482 4.482 0 004.488 4.477z"
      />
      <path
        fill="#5E5E5E"
        d="M134.048 119.355a3.217 3.217 0 003.221-3.213 3.217 3.217 0 00-3.221-3.213 3.217 3.217 0 00-3.221 3.213 3.217 3.217 0 003.221 3.213z"
      />
      <path
        fill="#1F1F1F"
        d="M134.048 117.523a1.382 1.382 0 100-2.762c-.764 0-1.384.618-1.384 1.381 0 .762.62 1.381 1.384 1.381z"
      />
      <path fill="#8C8C8C" d="M153.658 86.632h-39.22v23.678h39.22V86.632z" />
      <path fill="#000" d="M151.756 109.252h-35.443V87.755h35.443v21.497zm-35.197-.284h34.925V87.974h-34.925v20.994z" />
      <path
        fill="#171717"
        d="M145.366 115.316h-3.208a.814.814 0 00-.815.813v.013c0 .449.365.813.815.813h3.208c.45 0 .815-.364.815-.813v-.013a.814.814 0 00-.815-.813zm7.503 0h-3.208a.814.814 0 00-.815.813v.013c0 .449.365.813.815.813h3.208c.45 0 .815-.364.815-.813v-.013a.814.814 0 00-.815-.813z"
      />
      <path
        fill="#121212"
        d="M118.448 115.316h-3.208a.814.814 0 00-.815.813v.013c0 .449.365.813.815.813h3.208c.45 0 .815-.364.815-.813v-.013a.814.814 0 00-.815-.813zm7.503 0h-3.208a.814.814 0 00-.815.813v.013c0 .449.365.813.815.813h3.208c.45 0 .815-.364.815-.813v-.013a.814.814 0 00-.815-.813z"
      />
      <path
        fill="#7D7D7D"
        d="M113.882 81.394h42.544a1.96 1.96 0 011.979 1.923v35.999l-.556-35.342a2.09 2.09 0 00-2.005-1.935l-41.962-.645z"
      />
      <path fill="#FF4F69" d="M118.417 16.697l-4.325 37.573 7.093.812 4.325-37.573-7.093-.812z" />
      <path fill="#FF7A8E" d="M123.909 17.323l-4.325 37.573 1.62.185 4.324-37.572-1.619-.186z" />
      <path fill="#C93E53" d="M118.411 16.684l-4.325 37.573 3.547.406 4.325-37.572-3.547-.407z" />
      <path fill="#AD3548" d="M118.42 16.692l-4.324 37.573 1.773.203 4.325-37.573-1.774-.203z" />
      <path fill="#C4C4C4" d="M116.598 63.6l4.579-8.542-7.101-.813 2.522 9.355z" />
      <path fill="#424242" d="M116.598 63.6l1.526-2.839-2.367-.27.841 3.109z" />
      <path
        fill="#CF3AC3"
        d="M122.781 9.51h.065a3.532 3.532 0 012.358 1.294c.585.725.857 1.652.759 2.577l-.181 1.535-7.101-.813.181-1.535a3.52 3.52 0 011.328-2.335 3.537 3.537 0 012.591-.723z"
      />
      <path fill="#E8D7C3" d="M118.708 14.18l-.291 2.526 7.094.812.29-2.525-7.093-.813z" />
      <path fill="#FFBC4F" d="M96.182 32.283l-6.928 1.722 9.165 36.697 6.929-1.721-9.166-36.698z" />
      <path fill="#FFCA73" d="M96.19 32.27l-1.581.393 9.165 36.698 1.582-.393L96.19 32.27z" />
      <path fill="#D49C42" d="M92.745 33.081l-3.464.862 9.172 36.696 3.464-.862-9.172-36.696z" />
      <path fill="#BF8322" d="M90.985 33.562l-1.732.43 9.166 36.698 1.732-.43-9.166-36.698z" />
      <path fill="#C4C4C4" d="M104.09 78.542l1.28-9.613-6.933 1.729 5.653 7.884z" />
      <path fill="#424242" d="M104.09 78.542l.427-3.2-2.316.58 1.889 2.62z" />
      <path
        fill="#CF3AC3"
        d="M90.844 25.73h.065a3.54 3.54 0 013.709 1.328c.276.375.475.8.585 1.252l.376 1.51-6.934 1.728-.375-1.51a3.516 3.516 0 011.316-3.72 3.535 3.535 0 011.258-.589z"
      />
      <path fill="#E8D7C3" d="M95.571 29.804l-6.928 1.722.616 2.466 6.928-1.722-.616-2.466z" />
      <path
        fill="#C92818"
        d="M4.553 47.845c-.854-.98-1.914-1.42-2.419-1.006l-.116.103-.117.103c-.491.413-.245 1.536.57 2.58l30.177 37.11 3.338-2.76-31.433-36.13z"
      />
      <path
        fill="#8A1B11"
        d="M2.147 46.877l-.116.104-.117.103c-.491.413-.245 1.535.57 2.58l30.177 37.11 1.643-1.393S2.018 46.98 2.147 46.877z"
      />
      <path fill="#E8D7C3" d="M32.648 86.735l5.976 6.426 2.64-2.206-5.317-6.98-3.299 2.76z" />
      <path fill="#B0A394" d="M32.648 86.736l5.976 6.425 1.242-1.058-5.575-6.761-1.643 1.394z" />
      <path
        fill="#5C4A42"
        d="M38.573 93.174l2.587-2.206c9.378-.826 12.34 9.897 3.415 17.355 1.927-7.433-10.982-5.665-6.002-15.149z"
      />
      <path
        fill="#FFBC4F"
        d="M35.624 1.82C35.326.67 34.628-.117 34.045.025h-.284c-.582.142-.828 1.174-.556 2.322l12.56 54.568 3.88-.942L35.624 1.82z"
      />
      <path
        fill="#C7933E"
        d="M34.059.026h-.285c-.582.142-.828 1.174-.556 2.322l12.56 54.568 1.914-.49S33.916.103 34.06.026z"
      />
      <path fill="#E8D7C3" d="M45.765 56.98l2.302 7.691 3.066-.748-1.527-7.884-3.841.942z" />
      <path fill="#B0A394" d="M45.765 56.98l2.302 7.69 1.449-.373-1.837-7.807-1.914.49z" />
      <path fill="#5C4A42" d="M48.068 64.62l3.065-.75c8.033 3.11 2.2 16.027 2.2 16.027s-13.246-9.471-5.265-15.278z" />
    </svg>
  );
}

export default Design();
