import React from 'react';

function Pig() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="91" height="105" fill="none">
      <g>
        <path
          fill="#F6D738"
          d="M46.169 30.628c8.495 0 15.381-6.856 15.381-15.314S54.664 0 46.17 0c-8.495 0-15.381 6.856-15.381 15.314s6.886 15.314 15.381 15.314z"
        />
        <path
          fill="#F3CC30"
          d="M46.17 28.31c7.209 0 13.053-5.818 13.053-12.996 0-7.178-5.844-12.996-13.054-12.996-7.209 0-13.053 5.818-13.053 12.996 0 7.178 5.844 12.997 13.053 12.997z"
        />
        <path
          fill="#EDBD31"
          d="M52.994 4.222a12.953 12.953 0 01-1.682 16.116A13.102 13.102 0 0135.157 22.3a13.029 13.029 0 004.143 4.056 13.094 13.094 0 0011.272 1.167 13.06 13.06 0 004.893-3.122 12.988 12.988 0 003.048-4.927 12.945 12.945 0 00-1.372-11.2 13.028 13.028 0 00-4.148-4.052z"
        />
        <path
          fill="#F7DB5E"
          d="M35.401 16.503c0-2.396.754-4.732 2.155-6.68a11.52 11.52 0 015.659-4.181 11.572 11.572 0 017.045-.12 11.524 11.524 0 015.799 3.987 11.491 11.491 0 00-3.113-3.482 11.573 11.573 0 00-13.269-.332 11.497 11.497 0 00-3.285 3.322 11.444 11.444 0 00-1.496 9.006 11.45 11.45 0 002.036 4.197 11.382 11.382 0 01-1.53-5.717z"
        />
        <path
          fill="#DC8EA2"
          d="M29.84 97h-5.935a1.953 1.953 0 01-1.378-.568 1.936 1.936 0 01-.57-1.372l-1.639-11.122c0-.515.206-1.008.571-1.372a1.953 1.953 0 011.378-.568h9.21c.517 0 1.013.204 1.378.568.366.364.571.857.571 1.372L31.785 95.06c0 .514-.205 1.007-.57 1.37a1.953 1.953 0 01-1.374.57zm40.034 0h-5.939a1.953 1.953 0 01-1.377-.568 1.936 1.936 0 01-.571-1.372L60.35 83.933c0-.514.205-1.008.57-1.371a1.953 1.953 0 011.379-.569h9.21c.517 0 1.013.205 1.378.569.365.363.57.857.57 1.371L71.819 95.06c0 .514-.205 1.007-.57 1.37a1.953 1.953 0 01-1.374.57z"
        />
        <path
          fill="#E1A1B0"
          d="M81.609 65.214c0 16.564-15.872 29.995-35.45 29.995-19.578 0-35.45-13.43-35.45-29.995s12.117-30.977 35.45-30.977c23.355 0 35.45 14.412 35.45 30.977z"
        />
        <path
          fill="#E1A1B0"
          d="M20.318 80.53l-5.46-.025c-4.726.22-6.814-1.074-7.724-4.399L4.136 65.162a4.198 4.198 0 01.414-3.188 4.23 4.23 0 012.55-1.97l6.78-1.837a6.335 6.335 0 014.774.614 6.286 6.286 0 012.95 3.785l2.776 9.606c.919 3.325-.718 7.453-4.062 8.357zM31.71 40.282c-.095-.08-9.593-7.835-20.346-3.917a.328.328 0 00-.213.27c-.013.122-1.235 12.289 10.564 18.42a.33.33 0 00.423-.108l9.632-14.234a.326.326 0 00-.06-.431zm54.109 10.346a1.655 1.655 0 00-1.92.725c-2.075 3.415-5.771 4.076-7.345 4.358-.583.104-.85.149-1.109.368a.977.977 0 00-.3 1.067l2.596 7.54a.979.979 0 00.908.66.986.986 0 00.39-.071c6.403-2.582 7.731-9.227 7.953-12.96a1.636 1.636 0 00-.289-1.048 1.648 1.648 0 00-.884-.639z"
        />
        <path
          fill="#3E3D40"
          d="M23.445 60.29a2.134 2.134 0 002.139-2.129c0-1.176-.958-2.13-2.139-2.13a2.134 2.134 0 00-2.139 2.13c0 1.176.958 2.13 2.139 2.13z"
        />
        <path
          fill="#58595A"
          d="M56.411 42.798c-10.513-3.541-20.035-.052-20.13-.017l-1.04-2.745c.427-.162 10.614-3.89 22.115-.017l-.945 2.779z"
        />
        <path
          fill="#DE96A6"
          d="M20.768 86.135c6.435 5.594 15.438 9.074 25.39 9.074 19.218 0 34.865-12.943 35.433-29.086-19.435 25.86-48.17 22.692-60.823 20.011z"
        />
        <path
          fill="#E7B9C6"
          d="M5.46 66.123S3.531 61.3 11.114 60.05c0 0-4.342 2.13-5.654 6.073zm6.792-28.691s2.778-1.538 5.042-1.127c0 0-3.499 2.868-2.058 11.476 0 0-2.985-4.715-2.985-10.35z"
        />
        <path fill="#3E3D40" d="M45.294 41.234c3.77-.15 7.536.38 11.117 1.564l.944-2.78-12.061 1.215v.001z" />
      </g>
      <defs>
        <filter
          id="filter0_d"
          width="91"
          height="105"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default Pig();
