import Api from '../services/Api';
import config from '../config';
import { parseErrorsAPI } from '../utils';

const api = new Api(config.REACT_APP_API_URL);

export function sendMessage(data) {
  return new Promise((resolve, reject) => {
    api
      .post('notifications/send', { body: JSON.stringify(data) })
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        reject(parseErrorsAPI(error));
      });
  });
}
