import React, { Fragment } from 'react';
import LogoDark from './LogoDark';
import BitcomSolution from '../ui/BitcomSolution';

function Footer(props: any) {
  const { currentCity } = props;

  const renderCities = () => {
    const { address } = currentCity;
    return address.map((item: any) => {
      const { title } = item;
      return (
        <div key={title} className="footer-block-address">
          {title}
        </div>
      );
    });
  };

  const renderPhones = () => {
    const { phones } = currentCity;
    return phones.map((phone: any, index: number) => {
      return (
        <Fragment key={phone}>
          <a href={`tel:${phone.replace(/ |\(|\)/g, '')}`} className="footer-block-address footer-block-address__link">
            {phone}
          </a>
          {phones.length - 1 === index ? null : <p className="footer-block-address">,</p>}
        </Fragment>
      );
    });
  };

  return (
    <footer className="footer">
      <LogoDark />
      <div className="footer-icons">
        <div className="footer-icons-block">
          <a href="https://vk.com/saikt" target="_blank" className="footer-icons__icon" rel="noopener noreferrer">
            <i className="fab fa-vk" />
          </a>
          <a
            href="https://www.instagram.com/chudo_saikt/"
            target="_blank"
            className="footer-icons__icon"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram" />
          </a>
          <a
            title="Должен быть установлен Viber для ПК"
            href="viber://chat?number=%2B79136518935"
            target="_blank"
            className="footer-icons__icon"
            rel="noopener noreferrer"
          >
            <i className="fab fa-viber" />
          </a>
          <a href="https://wa.me/+79136518935" target="_blank" className="footer-icons__icon" rel="noopener noreferrer">
            <i className="fab fa-whatsapp" />
          </a>
        </div>
        <BitcomSolution />
      </div>
      <div className="footer-cities">
        {renderCities()}
        <div className="footer-block-numbers">{renderPhones()}</div>
        <div className="footer-block-numbers">
          <a href="mailto:edu@saikt.ru" className="footer-block-address footer-block-address__link">
            edu@saikt.ru
          </a>
        </div>
      </div>
      <BitcomSolution className="bitcom-solution-mobile" />
    </footer>
  );
}

export default Footer;
