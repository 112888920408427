import React from 'react';

function UserPC() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="162" height="150" fill="none">
      <g>
        <path
          className="our-course__icon"
          d="M75.332 150c41.605 0 75.333-33.579 75.333-75S116.937 0 75.333 0C33.727 0 0 33.579 0 75s33.727 75 75.332 75z"
        />
        <path
          fill="#fff"
          d="M134.652 98.75H16.012V39.16c0-1.933.772-3.787 2.145-5.154a7.339 7.339 0 015.177-2.136h104.009a7.34 7.34 0 015.178 2.135 7.274 7.274 0 012.144 5.155v59.59h-.013z"
        />
        <path fill="#E6E6E6" d="M134.652 98.763H16.012v-59.59l118.64 59.59z" />
        <path
          fill="#000"
          d="M16.012 98.75h118.654v3.256a7.274 7.274 0 01-2.145 5.155 7.337 7.337 0 01-5.177 2.135H23.322a7.34 7.34 0 01-5.178-2.135A7.273 7.273 0 0116 102.006v-3.255h.012z"
        />
        <path fill="#59BFFF" d="M130.223 36.838H20.716V94.6h109.507V36.838z" />
        <path fill="#FF4F69" d="M87.71 68.774H62.669v20.04h25.04v-20.04z" />
        <path
          fill="#fff"
          d="M71.294 73.755l-1.043 7.497a2.495 2.495 0 00-.73-.22 1.527 1.527 0 00-1.237 1.023 1.511 1.511 0 00.372 1.557 1.527 1.527 0 002.586-.881l.898-6.486 7.114-.09-.69 4.929a2.487 2.487 0 00-.73-.22 1.528 1.528 0 00-1.235 1.02 1.512 1.512 0 00.367 1.555 1.526 1.526 0 002.588-.864l1.303-8.91-9.563.09z"
        />
        <path fill="#1B79B3" d="M114.992 68.774H89.521v20.378h25.471V68.774z" />
        <path fill="#fff" d="M97.156 83.146v-8.367l11.061 4.19-11.061 4.177z" />
        <path fill="#1B79B3" d="M60.089 68.774H35.047v20.04H60.09v-20.04z" />
        <path
          fill="#fff"
          d="M55.177 78.398c0-.276-.04-.551-.117-.817-.626-2.179-3.74-3.891-7.492-3.891-4.208 0-7.608 2.075-7.608 4.644a3.88 3.88 0 00.612 1.845 3.916 3.916 0 001.433 1.32l-1.199 2.27 2.802-1.466h.13c2.53.88 5.286.88 7.817 0 2.163-.752 3.622-2.231 3.622-3.904zm-10.423.818a.823.823 0 01-.758-.505.814.814 0 01.598-1.114.825.825 0 01.843.348c.09.134.138.292.138.454a.815.815 0 01-.549.746.824.824 0 01-.324.045l.052.026zm2.866 0a.823.823 0 01-.758-.505.813.813 0 01.178-.89.822.822 0 011.401.578.815.815 0 01-.549.746.824.824 0 01-.324.045l.052.026zm2.867 0a.823.823 0 01-.759-.505.814.814 0 01.599-1.114.825.825 0 01.842.348c.09.134.139.292.139.454a.811.811 0 01-.268.58.822.822 0 01-.605.21l.052.027z"
        />
        <path fill="#1B79B3" d="M87.71 44.22H62.669v20.04h25.04V44.22z" />
        <path
          fill="#fff"
          d="M80.088 51.289h-9.837v.505h9.837v-.505zm-3.426 1.115h-6.41v.506h6.41v-.506zm1.315 1.634h-5.563l-.886-.505h7.322l-.873.505zm-1.915 1.116H74.33l-.925-.506h3.544l-.886.506z"
        />
        <path
          fill="#fff"
          d="M83.15 51.276l-7.44-4.281a1.033 1.033 0 00-1.055 0l-7.452 4.28v8.977a.517.517 0 00.52.518H82.63a.522.522 0 00.52-.518v-8.977zm-15.114 7.782v-.622h4.886v.57h-4.885v.052zm6.332-1.037h-6.331v-.52h6.331v.52zm.261-2.452l-5.355-3.074v-2.05H81v2.089l-5.211 3.022a1.033 1.033 0 01-1.12-.013l-.04.026zm5.615 3.048a.523.523 0 01-.573-.519.988.988 0 01-.873.52.772.772 0 01-.73-.548.762.762 0 01-.025-.322 1.523 1.523 0 01.454-1.113 1.537 1.537 0 011.122-.443c.274-.002.545.055.795.168l-.196 1.297c-.065.39 0 .571.17.584a.95.95 0 00.651-1.128 1.47 1.47 0 00-.45-1.133 1.485 1.485 0 00-1.152-.411 1.963 1.963 0 00-1.413.624 1.944 1.944 0 00-.516 1.451 1.615 1.615 0 00.494 1.22 1.63 1.63 0 001.24.454c.338.002.672-.073.976-.22l.118.388a2.772 2.772 0 01-1.225.247 2.054 2.054 0 01-1.509-.605 2.035 2.035 0 01-.589-1.51 2.468 2.468 0 01.695-1.837 2.488 2.488 0 011.82-.757 1.924 1.924 0 011.44.524 1.906 1.906 0 01.592 1.409 1.383 1.383 0 01-.725 1.479 1.4 1.4 0 01-.564.155l-.026.026z"
        />
        <path
          fill="#fff"
          d="M78.733 57.644a.35.35 0 00.339.415.769.769 0 00.573-.7l.091-.636h-.208a.887.887 0 00-.795.921z"
        />
        <path fill="#C92818" d="M114.992 44.22H89.521v20.377h25.471V44.22z" />
        <path
          fill="#fff"
          d="M106.471 49.356h-8.169a2.851 2.851 0 00-2.008.828 2.829 2.829 0 00-.832 2v3.074a2.818 2.818 0 00.832 2 2.843 2.843 0 002.008.827h5.694l4.052 3.36-1.238-3.386a2.816 2.816 0 001.741-.932c.448-.51.695-1.166.695-1.843v-3.1a2.82 2.82 0 00-.808-1.977 2.848 2.848 0 00-1.967-.851zm2.267 5.928c0 .591-.236 1.159-.656 1.577-.42.419-.99.654-1.585.654h-.43l.782 2.14-2.606-2.14h-5.954a2.277 2.277 0 01-1.603-.661 2.258 2.258 0 01-.664-1.596v-3.075c0-.598.239-1.172.664-1.596a2.272 2.272 0 011.603-.66h8.169c.601 0 1.178.237 1.603.66.425.424.664.998.664 1.596v3.1h.013z"
        />
        <path
          fill="#fff"
          d="M103.266 52.845v-1.154a1.788 1.788 0 00-1.511.843h-1.954a.984.984 0 100 1.972h.482l-.43 1.75h.821l.443-1.712h.638a1.782 1.782 0 001.511.844v-1.155a.691.691 0 00.63-.951.683.683 0 00-.63-.437zm3.023.493h-1.915v.402h1.915v-.402zm-2.063 1.102l-.286.284 1.354 1.348.286-.284-1.354-1.348zm1.059-3.42l-1.354 1.348.286.284 1.354-1.348-.286-.285z"
        />
        <path fill="#FFBC4F" d="M60.089 44.22H35.047v20.04H60.09V44.22z" />
        <path
          fill="#fff"
          d="M47.816 48.253v-.791h.703a.287.287 0 00.287-.286v-.415a.285.285 0 00-.287-.285h-2.397a.287.287 0 00-.287.285v.403a.285.285 0 00.287.285h.769v.804a6.904 6.904 0 00-4.65 2.245 6.844 6.844 0 00.321 9.534 6.909 6.909 0 004.791 1.93 6.908 6.908 0 004.791-1.93 6.844 6.844 0 00.322-9.534 6.904 6.904 0 00-4.65-2.245zm-.47 12.621a5.816 5.816 0 01-3.21-.991 5.778 5.778 0 01-2.118-2.599 5.749 5.749 0 011.282-6.277 5.821 5.821 0 016.313-1.234 5.792 5.792 0 012.596 2.126 5.754 5.754 0 01-.737 7.298 5.799 5.799 0 01-4.125 1.677z"
        />
        <path
          fill="#fff"
          d="M44.324 56.827v-.544l.495-.441a2.869 2.869 0 001.303-1.622.544.544 0 00-.626-.558c-.3.013-.588.123-.82.312l-.248-.636a2.13 2.13 0 011.303-.39 1.202 1.202 0 011.203.712c.066.152.1.316.1.482a2.367 2.367 0 01-1.017 1.648l-.351.298h1.446v.74h-2.788zm4.86 0v-.908h-1.707v-.583l1.446-2.322h1.095v2.244h.456v.674h-.456v.908h-.847l.013-.013zm0-1.582v-1.557c-.13.247-.222.467-.352.714l-.508.83h.86v.013zm-1.551-5.552h-.625v1.22h.625v-1.22zm0 9.599h-.625v1.22h.625v-1.22zm5.186-4.488h-1.225v.622h1.225v-.622zm-9.707 0h-1.224v.622h1.224v-.622zm7.92-3.65l-.544.541.277.275.543-.54-.276-.276zm-7.313.052l-.277.275.544.541.276-.275-.543-.54zm7.072 7.082l-.276.275.543.541.277-.275-.544-.541zm-6.897.002l-.544.541.276.276.544-.541-.276-.276z"
        />
        <path fill="#fff" d="M130.223 94.6L20.716 36.838h109.507V94.6z" opacity="0.1" />
        <path fill="#fff" d="M89.338 109.296H61.314v11.843h28.024v-11.843z" />
        <path fill="#C7C7C7" d="M89.338 109.296H61.314v5.202h28.024v-5.202z" />
        <path
          fill="#fff"
          d="M110.289 126.859H40.637c0-1.503.6-2.945 1.667-4.008a5.707 5.707 0 014.026-1.66h58.265a5.713 5.713 0 014.026 1.66 5.653 5.653 0 011.668 4.008z"
        />
        <path fill="#F2B509" d="M139.46 2.88H90.771v49.472h48.689V2.88z" />
        <path fill="#FFD766" d="M138.9 3.204H91.332v.545H138.9v-.545z" />
        <path fill="#fff" d="M138.9 6.317H91.332v45.335H138.9V6.317z" />
        <path
          fill="#1B79B3"
          d="M102.224 9.508h-8.221v8.185h8.221V9.508zm0 10.753h-8.221v8.185h8.221v-8.185zm10.814 0h-8.222v8.185h8.222v-8.185zm0-10.753h-8.222v8.185h8.222V9.508zm10.814 10.753h-8.222v8.185h8.222v-8.185zm0-10.753h-8.222v8.185h8.222V9.508z"
        />
        <path fill="#BABABA" d="M137.193 9.508h-11.426V28.46h11.426V9.51z" />
        <path
          fill="#C9C9C9"
          d="M113.754 30.093H94.068v18.329h19.686V30.093zm23.413 0h-22.11v1.025h22.11v-1.025zm0 2.18h-22.11v1.024h22.11v-1.024zm0 2.192h-22.11v1.024h22.11v-1.024zm0 2.192h-22.11v1.025h22.11v-1.025zm-10.371 2.179h-11.739v1.025h11.739v-1.025zm10.371 4.384h-22.11v1.025h22.11V43.22zm-10.371 2.18h-11.739v1.024h11.739V45.4z"
        />
        <path fill="#FF4040" d="M162 43.415h-58.069v46.8H162v-46.8z" />
        <path fill="#FF8585" d="M161.323 44.025h-56.728v.817h56.728v-.817z" />
        <path fill="#fff" d="M161.336 47.527h-56.741v41.871h56.741V47.527z" />
        <path
          fill="#76A9AB"
          d="M109.754 53.208l-.899.895 1.629 1.609-1.629 1.621.899.895 2.528-2.516-2.528-2.504zm3.544 6.162l-.899.895 1.629 1.621-1.629 1.621.899.896 2.528-2.517-2.528-2.516zm-3.544 12.335l-.899.895 1.629 1.609-1.629 1.621.899.895 2.528-2.516-2.528-2.504zm5.316-6.174l-.899.895 1.629 1.621-1.629 1.622.899.895 2.515-2.517-2.515-2.516zm0 12.336l-.899.895 1.629 1.621-1.629 1.621.899.896 2.515-2.517-2.515-2.516z"
        />
        <path
          fill="#8A8A8A"
          d="M137.988 54.648h-20.703v2.127h20.703v-2.127zm19.087 6.174h-38.161v2.128h38.161v-2.128zm-6.71 12.323h-36.636v2.127h36.636v-2.127zm6.71-6.161h-37.014v2.127h37.014v-2.127zm0 12.336h-38.161v2.127h38.161v-2.128zm-7.153-24.672h-9.394v2.127h9.394v-2.127z"
        />
        <path fill="#1B79B3" d="M48.141 12.997H0v42.948h48.141V12.997z" />
        <path fill="#26A9FA" d="M47.425 13.412H.717v.688h46.708v-.688z" />
        <path fill="#fff" d="M47.425 17.407H.717V55.05h46.708V17.408z" />
        <path
          fill="#C9C9C9"
          d="M31.92 41.456H2.919v1.31h29.003v-1.31zm6.176 2.789H2.918v1.31h35.178v-1.31zm7.114 2.789H2.918v1.31H45.21v-1.31zm-13.29 2.789H2.919v1.31h29.003v-1.31z"
        />
        <path fill="#1B79B3" d="M12.377 18.873H4.365v7.978h8.012v-7.978z" />
        <path fill="#C92818" d="M28.077 18.873h-8.013v7.978h8.013v-7.978z" />
        <path fill="#FF4040" d="M43.777 18.873h-8.013v7.978h8.013v-7.978z" />
        <path fill="#FF4F69" d="M12.377 29.769H4.365v7.977h8.012V29.77z" />
        <path fill="#F2B509" d="M28.077 29.769h-8.013v7.977h8.013V29.77z" />
        <path fill="#1B79B3" d="M43.777 29.769h-8.013v7.977h8.013V29.77z" />
      </g>
    </svg>
  );
}

export default UserPC();
