import React from 'react';

function AcademyLife() {
  return (
    <div className="academy-life">
      <div className="box academy1" />
      <div className="box academy2" />
      <div className="box academy3" />
      <div className="box academy4" />
      <div className="box academy5" />
      <div className="box academy6" />
      <div className="box academy7" />
    </div>
  );
}

export default AcademyLife;
