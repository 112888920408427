import React, { useState } from 'react';
import Input from '../ui/Input';
import { sendMessage } from '../../actions/home';
import GlobalPortal from '../ui/GlobalPortal';
import SuccessPopup from './popups/SuccessPopup';
import validator from 'validator';

type Props = {
  theme: string;
  titles: Array<string>;
  subtitle: string;
  id?: string;
  ymTarget?: any;
};

function Consultation(props: Props) {
  const { titles, subtitle, theme = '', id = '', ymTarget } = props;

  const [name, toggleName] = useState('');
  const [nameError, toggleNameError] = useState('');
  const [phone, togglePhone] = useState('');
  const [phoneError, togglePhoneError] = useState('');
  const [successRequest, toggleSuccessRequest] = useState(false);
  const [apiError, toggleApiError] = useState('');

  const validateInput = () => {
    let error = false;

    if (!name) {
      toggleNameError('Введите имя');
      error = true;
    } else {
      toggleNameError('');
      error = false;
    }

    return error;
  };

  const validatePhone = () => {
    let error = false;

    if (!validator.isMobilePhone(phone)) {
      error = true;
      togglePhoneError('Неверный формат');
    } else {
      togglePhoneError('');
      error = false;
    }

    return error;
  };

  const replacePhoneNumber = (value: string) => {
    togglePhone(value.replace(/ /g, ''));
  };

  const validate = () => {
    return validateInput() || validatePhone();
  };

  const submit = () => {
    if (validate()) return;

    sendMessage({
      name,
      phone,
    })
      .then(() => {
        // @ts-ignore
        if (ymTarget) {
          ymTarget();
        }
        toggleApiError('');
        toggleSuccessRequest(true);
      })
      .catch((error) => {
        toggleApiError(error);
      });
  };

  const renderTitles = () => {
    return titles.map((title) => {
      return (
        <h2 key={title} className="consultation-desc__title">
          {title}
        </h2>
      );
    });
  };

  const closePopup = () => {
    toggleName('');
    togglePhone('');
    toggleSuccessRequest(false);
  };

  return (
    <div id={id} className={`consultation ${theme}`}>
      <div className="consultation-desc">
        {renderTitles()}
        <h2 className="consultation-desc__title-sub">{subtitle}</h2>
      </div>
      <div className="consultation-form">
        <Input
          value={name}
          placeholder="Имя"
          onChange={(event: any) => toggleName(event.target.value)}
          error={nameError}
        />
        <Input
          value={phone}
          type="tel"
          onChange={(event: any) => replacePhoneNumber(event.target.value)}
          placeholder="Телефон"
          error={phoneError}
        />
        {apiError && <p className="error-message">{apiError}</p>}
        <button className="main-button" type="button" onClick={submit}>
          Оставить заявку
        </button>
      </div>
      {successRequest && (
        <GlobalPortal close={closePopup}>
          <SuccessPopup close={closePopup} />
        </GlobalPortal>
      )}
    </div>
  );
}

export default Consultation;
