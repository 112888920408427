import React, { useState } from 'react';

interface Props {
  text: string;
}

function ReadMore(props: Props) {
  const { text } = props;

  const [sliceText, toggleSliceText] = useState(text.slice(0, 400));
  const [showReadMore, toggleReadMore] = useState(true);

  const showText = () => {
    toggleSliceText(text);
    toggleReadMore(false);
  };

  return (
    <div className="review-footer">
      <p className="review-footer__text">{sliceText}</p>
      {showReadMore && (
        <p className="review-footer__read-more" onClick={showText}>
          Читать дальше {'>>'}
        </p>
      )}
    </div>
  );
}

export default ReadMore;
