import React, { useEffect, useState } from 'react';
import { Link } from '../home/Main';
import { NavLink } from 'react-router-dom';

type Props = {
  links: Array<Link>;
};

function Navbar(props: Props) {
  const { links } = props;

  const [activeElem, toggleActiveElem] = useState('');

  const scroll = (event: any, to: string) => {
    event.preventDefault();
    const selector = `#${to}`;
    const element = document.querySelector(selector);
    if (!element) return;
    toggleActiveElem(selector);
    window.history.replaceState(null, '', `/${selector}`);
    element.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    toggleActiveElem(window.location.hash);
  }, []);

  const renderLinks = () => {
    return links.map((link) => {
      const { name, to } = link;
      const active = () => activeElem === `#${to}`;
      return (
        <li key={link.name} className="navbar__li">
          <NavLink isActive={active} className="navbar__link" onClick={(event) => scroll(event, to)} to={`/#${to}`}>
            {name}
          </NavLink>
        </li>
      );
    });
  };

  return <ul className="navbar">{renderLinks()}</ul>;
}

export default Navbar;
