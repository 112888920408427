import React from 'react';

function SelectQuiz() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="121" height="122" fill="none">
      <path fill="#374557" d="M87.462 115.406H33.221l4.585-19.471h45.071l4.585 19.471z" />
      <path
        fill="#2F3B4A"
        d="M60.342 101.47c8 0 16.426 1.288 24.519 2.88l-1.983-8.42H37.806l-1.983 8.42c8.093-1.59 16.52-2.88 24.52-2.88z"
      />
      <path
        fill="#252F3B"
        d="M60.342 97.122c8.253 0 16.07.224 23.03.918l-.494-2.105H37.806l-.495 2.105c6.961-.694 14.777-.918 23.03-.918z"
      />
      <path
        fill="#2F3B4A"
        d="M60.814 113.381c-9.078 0-18.137-1.171-25.742-4.082l-1.857 6.107h54.248l-1.858-6.107c-7.599 2.911-15.708 4.082-24.79 4.082z"
      />
      <path
        fill="#252F3B"
        d="M33.951 112.992l-.732 2.414h10.265a70.92 70.92 0 01-9.533-2.414zm53.511 2.416l-.722-2.383a67.439 67.439 0 01-8.984 2.383h9.706z"
      />
      <path
        fill="#E9E9E9"
        d="M102.673 114.782H18.01a3.342 3.342 0 00-3.347 3.338v.543A3.342 3.342 0 0018.01 122h84.663a3.342 3.342 0 003.347-3.337v-.543a3.342 3.342 0 00-3.347-3.338z"
      />
      <path
        fill="#fff"
        d="M26.026 115.488h68.633a.76.76 0 01.76.757v.124a.76.76 0 01-.762.759H26.026a.764.764 0 01-.761-.759v-.116a.757.757 0 01.761-.765z"
      />
      <path
        fill="#000"
        d="M104.184 115.146c0 .101.014.206.014.303v.531c0 .855-.34 1.675-.947 2.28a3.24 3.24 0 01-2.286.944H19.718a3.245 3.245 0 01-2.287-.944 3.22 3.22 0 01-.948-2.28v-.523c0-.103 0-.206.017-.302a3.34 3.34 0 00-1.837 2.975v.525c0 .885.353 1.734.98 2.36a3.355 3.355 0 002.367.977h84.663c.888 0 1.739-.352 2.367-.977.627-.626.98-1.475.98-2.36v-.542a3.327 3.327 0 00-.498-1.741 3.349 3.349 0 00-1.338-1.226z"
        opacity="0.2"
      />
      <path
        fill="#000"
        d="M102.673 120.556H18.01a3.36 3.36 0 01-2.204-.827 3.337 3.337 0 01-1.112-2.07 3.378 3.378 0 00-.033.463v.533c0 .885.353 1.734.98 2.359a3.351 3.351 0 002.367.978h84.665c.888 0 1.739-.352 2.367-.978.628-.625.98-1.474.98-2.359v-.542c0-.155-.011-.309-.033-.463a3.335 3.335 0 01-1.109 2.075 3.352 3.352 0 01-2.205.831z"
        opacity="0.2"
      />
      <path
        fill="#E9E9E9"
        d="M117.075 21.086H3.608a3.124 3.124 0 00-3.129 3.12v68.61a3.124 3.124 0 003.129 3.119h113.467a3.123 3.123 0 003.128-3.12v-68.61c0-1.722-1.4-3.119-3.128-3.119z"
      />
      <path
        fill="#A1A1A1"
        d="M60.341 94.186a2.689 2.689 0 002.693-2.685 2.689 2.689 0 00-2.693-2.685 2.689 2.689 0 00-2.693 2.685 2.689 2.689 0 002.693 2.685z"
      />
      <path
        fill="#fff"
        d="M60.342 93.906a2.409 2.409 0 002.412-2.405 2.409 2.409 0 00-2.412-2.406 2.409 2.409 0 00-2.412 2.406 2.409 2.409 0 002.412 2.405z"
      />
      <path
        fill="#374557"
        d="M60.342 93.103c.887 0 1.607-.717 1.607-1.602 0-.886-.72-1.603-1.607-1.603-.888 0-1.608.717-1.608 1.603 0 .885.72 1.602 1.608 1.602z"
      />
      <path
        fill="#A1A1A1"
        d="M54.814 92.762c.698 0 1.264-.565 1.264-1.261 0-.697-.566-1.262-1.264-1.262a1.262 1.262 0 100 2.522z"
      />
      <path fill="#374557" d="M54.814 92.39a.89.89 0 00.892-.89.89.89 0 00-.892-.888.89.89 0 100 1.778z" />
      <path
        fill="#A1A1A1"
        d="M65.87 92.762c.698 0 1.264-.565 1.264-1.261 0-.697-.566-1.262-1.265-1.262a1.262 1.262 0 100 2.522z"
      />
      <path
        fill="#374557"
        d="M65.869 92.39a.89.89 0 00.891-.89.89.89 0 00-.891-.888.89.89 0 00-.891.889c0 .49.399.889.89.889zm51.215-71.304H3.599a3.132 3.132 0 00-2.208.919 3.115 3.115 0 00-.912 2.207v63.217h119.724V24.212a3.113 3.113 0 00-.911-2.207 3.131 3.131 0 00-2.208-.919z"
      />
      <path
        fill="#425369"
        d="M7.443 23.183H113.24c.602 0 2.172-.79 2.172-1.766a.962.962 0 00-.06-.327H5.33a.979.979 0 00-.06.327c0 .97 1.57 1.766 2.173 1.766z"
      />
      <path
        fill="#27313D"
        d="M119.766 22.631c.151.37.229.767.229 1.167v63.225H.479v.412h119.724V24.212c0-.557-.152-1.103-.437-1.58z"
      />
      <path fill="#E8E8D8" d="M114.288 26.962H6.396v56.749h107.892V26.963z" />
      <path fill="#FAFAF0" d="M113.295 27.627H7.388v55.417h105.907V27.627z" />
      <path fill="#2C5391" d="M104.19 26.962H16.491v56.749h87.699V26.963z" />
      <path fill="#008BD2" d="M103.562 26.962H17.12v56.749h86.442V26.963z" />
      <path fill="#242D38" d="M104.19 26.962H16.491v6.046h87.699v-6.046z" />
      <path
        fill="#000"
        d="M118.462 21.413l-1.486 67.927a2.984 2.984 0 01-.85 2.126 3.004 3.004 0 01-2.108.905H6.665a3.004 3.004 0 01-2.108-.904 2.987 2.987 0 01-.85-2.127L2.22 21.413a3.121 3.121 0 00-1.742 2.799v68.606a3.12 3.12 0 00.914 2.201 3.133 3.133 0 002.206.916h113.485c.829 0 1.625-.329 2.212-.914a3.117 3.117 0 00.916-2.206V24.213a3.12 3.12 0 00-1.75-2.798z"
        opacity="0.1"
      />
      <path
        fill="#000"
        d="M118.462 21.413a3.087 3.087 0 011.68 2.828l-1.486 67.665a3.084 3.084 0 01-.943 2.13 3.1 3.1 0 01-2.167.866H5.134a3.1 3.1 0 01-2.165-.868 3.083 3.083 0 01-.942-2.128L.541 24.249a3.08 3.08 0 011.68-2.827 3.121 3.121 0 00-1.742 2.79v68.606a3.12 3.12 0 00.914 2.201 3.133 3.133 0 002.206.916h113.485c.829 0 1.625-.329 2.212-.914a3.117 3.117 0 00.916-2.206V24.213a3.12 3.12 0 00-1.75-2.798z"
        opacity="0.2"
      />
      <path
        fill="#FAFAF0"
        d="M100.177 31.236a1.223 1.223 0 100-2.444c-.677 0-1.226.547-1.226 1.222 0 .675.549 1.222 1.226 1.222z"
      />
      <path
        fill="#D69551"
        d="M96.161 31.236c.677 0 1.226-.547 1.226-1.222 0-.675-.549-1.222-1.226-1.222a1.223 1.223 0 100 2.444z"
      />
      <path
        fill="#65BB69"
        d="M92.146 31.236c.676 0 1.225-.547 1.225-1.222 0-.675-.549-1.222-1.225-1.222-.677 0-1.226.547-1.226 1.222 0 .675.549 1.222 1.226 1.222z"
      />
      <path
        fill="#fff"
        d="M7.388 27.627v20.556l20.72-20.556H7.388zm36.639 0h-9.139l-27.5 27.284v9.066l12.654-12.552 23.985-23.798zm6.782 0L7.388 70.705v12.34h9.551l55.859-55.418h-21.99zm35.415 0H79.58L57.502 49.54 23.719 83.044h6.646l20.501-20.34 35.358-35.077zm23.595 0H93.006L37.149 83.044h16.81l19.436-19.282 36.425-36.135zm-7.671 14.337L60.74 83.044h6.07l8.83-8.76 37.656-37.359v-6.02l-11.148 11.06z"
        opacity="0.2"
      />
      <path
        fill="#D9D9D9"
        d="M44.66 9.755v3.926h63.813V9.755c0-5.38-3.508-9.742-8.895-9.742H37.21c4.849.572 7.449 4.753 7.449 9.742z"
      />
      <path
        fill="#C7C7C7"
        d="M44.66 9.755v3.926h48.825V9.755c0-4.89 1.135-8.313 6.093-9.743-.288-.024-.58 0-.874 0H40.009a9.872 9.872 0 00-1.822.173c4.22.982 6.473 4.926 6.473 9.57z"
      />
      <path fill="#000" d="M22.958 21.086L26.21 83.71h67.56l2.573-62.623H22.958z" opacity="0.1" />
      <path
        fill="#fff"
        d="M99.506 0H38.018a9.784 9.784 0 00-6.908 2.854 9.729 9.729 0 00-2.862 6.889v73.953h63.805V9.743c0-4.99 2.604-9.171 7.453-9.743z"
      />
      <path
        fill="#000"
        d="M28.32 68.775v14.934h27.253c-8.85-1.033-25.039-5.247-27.253-14.934zm36.285 14.934H92.13V65.156C89.05 80.06 73.357 82.686 64.605 83.71z"
        opacity="0.03"
      />
      <path
        stroke="#B8B8B8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.68"
        d="M40.813 74.972c3.433 0 6.215-2.775 6.215-6.197 0-3.423-2.782-6.198-6.215-6.198-3.432 0-6.214 2.775-6.214 6.198 0 3.422 2.782 6.197 6.214 6.197z"
      />
      <path
        fill="#ADADAD"
        d="M78.821 8.134v6.107H41.862V8.134h36.96zm0-.369H41.862a.37.37 0 00-.369.369v6.107a.368.368 0 00.37.368H78.82a.37.37 0 00.37-.368V8.134a.368.368 0 00-.37-.369z"
      />
      <path
        fill="#D9D9D9"
        d="M53.881 24.018H82.15a1.09 1.09 0 001.09-1.086 1.085 1.085 0 00-1.09-1.087H53.881a1.091 1.091 0 00-1.089 1.087 1.085 1.085 0 001.09 1.086zm28.261 7.218H53.876a1.091 1.091 0 00-1.09 1.087 1.085 1.085 0 001.09 1.086h28.267a1.091 1.091 0 001.09-1.086 1.085 1.085 0 00-1.09-1.087zm-28.261-2.522h21.73a1.091 1.091 0 001.09-1.087 1.085 1.085 0 00-1.09-1.086H53.88a1.09 1.09 0 00-1.089 1.086 1.085 1.085 0 001.09 1.087z"
      />
      <path
        fill="#C7C7C7"
        d="M82.142 47.115H53.876a1.091 1.091 0 00-1.09 1.086 1.085 1.085 0 001.09 1.086h28.267a1.091 1.091 0 001.09-1.086 1.085 1.085 0 00-1.09-1.086zm-28.261 6.868h21.73a1.09 1.09 0 001.09-1.087 1.085 1.085 0 00-1.09-1.086H53.88a1.091 1.091 0 00-1.089 1.086 1.085 1.085 0 001.09 1.087zm0-9.391h21.73a1.09 1.09 0 001.09-1.087 1.085 1.085 0 00-1.09-1.086H53.88a1.091 1.091 0 00-1.089 1.086 1.085 1.085 0 001.09 1.087z"
      />
      <path
        fill="#D9D9D9"
        d="M82.142 67.688H53.876a1.091 1.091 0 00-1.09 1.087 1.085 1.085 0 001.09 1.086h28.267a1.091 1.091 0 001.09-1.086 1.085 1.085 0 00-1.09-1.087zm-28.261 6.868h21.73a1.09 1.09 0 001.09-1.086 1.085 1.085 0 00-1.09-1.086H53.88a1.091 1.091 0 00-1.089 1.086 1.085 1.085 0 001.09 1.087zm0-9.399h21.73a1.091 1.091 0 001.09-1.086 1.085 1.085 0 00-1.09-1.086H53.88a1.091 1.091 0 00-1.089 1.086 1.085 1.085 0 001.09 1.087z"
      />
      <path
        fill="#1B79B3"
        d="M45.845 22.613l-6.147 6.125-2.27-2.263-1.434 1.42 2.988 2.977a1.033 1.033 0 001.444 0l6.855-6.835-1.436-1.424z"
      />
      <path
        stroke="#B8B8B8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.68"
        d="M44.23 22.452a6.268 6.268 0 00-7.928.84 6.228 6.228 0 00-.659 7.923A6.253 6.253 0 0039.1 33.66a6.27 6.27 0 004.225-.315 6.246 6.246 0 003.055-2.928 6.22 6.22 0 00.481-4.198"
      />
      <path
        fill="#1B79B3"
        d="M45.845 43.187l-6.147 6.127-2.27-2.263-1.434 1.42 2.988 2.977a1.033 1.033 0 001.444 0l6.855-6.835-1.436-1.426z"
      />
      <path
        stroke="#B8B8B8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.68"
        d="M44.23 43.026a6.268 6.268 0 00-7.928.84 6.228 6.228 0 00-.659 7.923 6.253 6.253 0 003.457 2.444 6.27 6.27 0 004.225-.315 6.247 6.247 0 003.055-2.928 6.221 6.221 0 00.481-4.199"
      />
    </svg>
  );
}

export default SelectQuiz;
