import React, { useState, useEffect } from 'react';
import GlobalPortal from '../ui/GlobalPortal';
import ConsultationPopup from '../home/popups/ConsultationPopup';

function NeedCall() {
  const [openBanner, toggleBanner] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const checkScroll = () => {
    if (window.pageYOffset > 400) setShowButton(true);
    else if (window.pageYOffset <= 400) setShowButton(false);
  };

  useEffect(() => {
    document.addEventListener('scroll', checkScroll);

    return function cleanup() {
      document.removeEventListener('scroll', checkScroll);
    };
  });

  return (
    <>
      {showButton && (
        <div id="need-call" className="need-call" onClick={() => toggleBanner(true)}>
          <div className="need-call-in">
            <i className="fas fa-2x fa-phone-alt" />
          </div>
        </div>
      )}
      {openBanner && (
        <GlobalPortal close={() => toggleBanner(false)}>
          <ConsultationPopup close={() => toggleBanner(false)} buttonTitle="Заказать обратный звонок" />
        </GlobalPortal>
      )}
    </>
  );
}

export default NeedCall;
