import React, { Fragment } from 'react';

function CityList(props: any) {
  const { currentCity } = props;

  const renderCities = () => {
    const { address } = currentCity;
    return address.map((item: any) => {
      const { title, type } = item;
      const className = type === 'gray' ? 'header-block-address header-block-address_gray' : 'header-block-address';
      return (
        <div key={title} className={className}>
          {title}
        </div>
      );
    });
  };

  const renderPhones = () => {
    const { phones } = currentCity;
    return phones.map((phone: any, index: number) => {
      return (
        <Fragment key={phone}>
          <a href={`tel:${phone.replace(/ |\(|\)/g, '')}`} className="header-block-address header-block-address__link">
            {phone}
          </a>
          {phones.length - 1 === index ? null : <p className="header-block-address">,</p>}
        </Fragment>
      );
    });
  };

  return (
    <div className="header-cities">
      {renderCities()}
      <div className="header-block-numbers">{renderPhones()}</div>
    </div>
  );
}

export default CityList;
