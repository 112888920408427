import React from 'react';

function Card() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="none">
      <path fill="#FF5E5E" d="M35 70c19.33 0 35-15.67 35-35S54.33 0 35 0 0 15.67 0 35s15.67 35 35 35z" />
      <path
        fill="#FEEA3B"
        d="M55.303 51.672H14.697a3.691 3.691 0 01-3.691-3.692V22.02a3.691 3.691 0 013.691-3.692h40.606a3.691 3.691 0 013.691 3.692v25.96a3.691 3.691 0 01-3.691 3.692z"
      />
      <path fill="#455A64" d="M58.994 24.404H11.006v8.34h47.988v-8.34z" />
      <path
        fill="#FF5E5E"
        d="M31.514 36.64H15.928v1.3h15.586v-1.3zm-2.94 7.52H15.928v1.299h12.646V44.16zm-2.256-3.76h-10.39v1.3h10.39v-1.3z"
      />
    </svg>
  );
}

export default Card;
