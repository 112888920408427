import React from 'react';

function Python() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="151" height="150" fill="none">
      <path
        className="our-course__icon"
        d="M75.332 150c41.605 0 75.333-33.579 75.333-75S116.937 0 75.333 0C33.727 0 0 33.579 0 75s33.727 75 75.332 75z"
      />
      <path
        fill="#000"
        d="M16.012 98.75h118.654v3.256a7.274 7.274 0 01-2.145 5.155 7.337 7.337 0 01-5.177 2.135H23.322a7.34 7.34 0 01-5.178-2.135A7.273 7.273 0 0116 102.006v-3.255h.012z"
      />
      <path
        fill="#fff"
        d="M134.64 98.88H16V39.29a7.27 7.27 0 012.145-5.155A7.338 7.338 0 0123.322 32h104.009a7.34 7.34 0 015.178 2.135 7.274 7.274 0 012.144 5.155v59.59h-.013z"
      />
      <path fill="#E6E6E6" d="M134.64 98.893H16v-59.59l118.64 59.59z" />
      <path fill="#1B79B3" d="M130.21 36.968H20.703v57.761H130.21V36.968z" />
      <path fill="#fff" d="M89.338 109.296H61.314v11.843h28.024v-11.843z" />
      <path fill="#C7C7C7" d="M89.338 109.296H61.314v5.202h28.024v-5.202z" />
      <path
        fill="#fff"
        d="M110.289 126.859H40.637c0-1.503.6-2.945 1.667-4.008a5.707 5.707 0 014.026-1.66h58.265a5.713 5.713 0 014.026 1.66 5.653 5.653 0 011.668 4.008zM63.516 69.281a4.517 4.517 0 014.511-4.512h13.946c1.13 0 2.05-.92 2.05-2.05V51.152A6.16 6.16 0 0077.871 45H72.13a6.16 6.16 0 00-6.152 6.152v3.364H75a1.23 1.23 0 110 2.46H60.152A6.16 6.16 0 0054 63.13v5.742a6.16 6.16 0 006.152 6.152h3.364v-5.742zm8.613-18.047a1.23 1.23 0 110-2.46 1.23 1.23 0 010 2.46z"
      />
      <path
        fill="#fff"
        d="M89.848 56.977h-3.364v5.742a4.517 4.517 0 01-4.511 4.512H68.027c-1.13 0-2.05.92-2.05 2.05v11.567A6.16 6.16 0 0072.129 87h5.742a6.16 6.16 0 006.152-6.152v-3.364H75a1.23 1.23 0 110-2.46h14.848A6.16 6.16 0 0096 68.87v-5.74a6.16 6.16 0 00-6.152-6.152zM77.87 80.766a1.23 1.23 0 110 2.46 1.23 1.23 0 010-2.46z"
      />
    </svg>
  );
}

export default Python();
