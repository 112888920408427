import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import Home from '../../pages/home';
import '../../assets/styles/app.scss';

function HomeLayout({ title }: any) {
  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container">
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
        </Switch>
      </div>
    </Fragment>
  );
}

function mapStateToProps({ app }: any) {
  return {
    ...app,
  };
}

export default connect(mapStateToProps)(HomeLayout);
