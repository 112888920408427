import React from 'react';

function Contact() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="74" height="74" fill="none">
      <path fill="#005C83" d="M37 74c20.434 0 37-16.566 37-37S57.434 0 37 0 0 16.566 0 37s16.566 37 37 37z" />
      <path fill="#fff" d="M53.693 13.297H20.308v46.608h33.385V13.297z" />
      <path fill="#FFDC00" d="M47.02 55.888h-6.359V67.16h6.36V55.888z" />
      <path
        fill="#E0E0E0"
        d="M47.682 20.483H26.316v2.89h21.366v-2.89zm0 6.504H26.316v2.89h21.366v-2.89zm0 6.503H26.316v2.891h21.366v-2.89zm0 6.505H26.316v2.89h21.366v-2.89z"
      />
      <path
        fill="#F1333F"
        d="M36.566 52.275c0-.928 1.02-1.646 1.354-2.453.346-.836.147-2.061.777-2.691.63-.63 1.856-.431 2.692-.778C42.195 46.02 42.914 45 43.84 45c.928 0 1.646 1.019 2.453 1.353.836.347 2.061.148 2.691.778.63.63.431 1.855.778 2.691.334.807 1.353 1.525 1.353 2.453 0 .927-1.019 1.646-1.353 2.452-.347.837-.148 2.062-.778 2.692-.63.63-1.855.43-2.691.777-.807.335-1.525 1.354-2.453 1.354-.928 0-1.646-1.02-2.453-1.354-.836-.346-2.06-.147-2.69-.777-.631-.63-.432-1.856-.778-2.692-.335-.806-1.354-1.525-1.354-2.452z"
      />
      <path fill="#FF9451" d="M43.841 56.346a4.071 4.071 0 100-8.143 4.071 4.071 0 000 8.143z" />
    </svg>
  );
}

export default Contact;
