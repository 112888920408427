import React from 'react';

function Stopwatch() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="none">
      <path fill="#84DBFF" d="M35 70c19.33 0 35-15.67 35-35S54.33 0 35 0 0 15.67 0 35s15.67 35 35 35z" />
      <path
        fill="#324A5E"
        d="M35 20.284a7.151 7.151 0 01-7.166-7.166A7.151 7.151 0 0135 5.953a7.151 7.151 0 017.165 7.165c0 3.914-3.197 7.165-7.165 7.165zm0-13.229a6.046 6.046 0 00-6.063 6.063c0 3.362 2.7 6.063 6.063 6.063 3.362 0 6.063-2.7 6.063-6.063A6.046 6.046 0 0035 7.055z"
      />
      <path
        fill="#2B3B4E"
        d="M53.465 22.378a.8.8 0 00-1.158 0l-7.11 7.11a.8.8 0 000 1.158c.166.165.386.22.606.22.221 0 .442-.055.607-.22l7.11-7.11a.852.852 0 00-.055-1.158zm-35.772 0a.8.8 0 00-1.157 0 .8.8 0 000 1.157l7.11 7.11c.165.166.386.221.606.221.22 0 .441-.055.607-.22a.8.8 0 000-1.158l-7.166-7.11zm19.347-7.662h-4.134v7.827h4.134v-7.826z"
      />
      <path
        fill="#324A5E"
        d="M31.086 16.535v-2.26c0-.496.441-.937.937-.937h5.953c.496 0 .937.441.937.938v2.26c0 .495-.44.936-.937.936h-5.953a.93.93 0 01-.937-.937zM35 64.047c11.963 0 21.661-9.698 21.661-21.661 0-11.964-9.698-21.662-21.661-21.662-11.963 0-21.662 9.698-21.662 21.662 0 11.963 9.699 21.661 21.662 21.661z"
      />
      <path
        fill="#E6E9EE"
        d="M35 60.41c9.954 0 18.024-8.07 18.024-18.024S44.954 24.362 35 24.362s-18.023 8.07-18.023 18.024S25.046 60.409 35 60.409z"
      />
      <path
        fill="#ACB3BA"
        d="M35 29.268a1.654 1.654 0 100-3.307 1.654 1.654 0 000 3.307zm0 29.764a1.654 1.654 0 100-3.307 1.654 1.654 0 000 3.307zM49.882 44.15a1.654 1.654 0 100-3.307 1.654 1.654 0 000 3.307zm-29.764 0a1.654 1.654 0 100-3.307 1.654 1.654 0 000 3.307zm7.441-12.898a1.654 1.654 0 100-3.307 1.654 1.654 0 000 3.307zM42.44 57.047a1.654 1.654 0 100-3.307 1.654 1.654 0 000 3.307zm5.457-20.338a1.654 1.654 0 100-3.307 1.654 1.654 0 000 3.307zM22.102 51.59a1.654 1.654 0 100-3.306 1.654 1.654 0 000 3.307zm0-14.881a1.654 1.654 0 100-3.307 1.654 1.654 0 000 3.307zM47.897 51.59a1.654 1.654 0 100-3.306 1.654 1.654 0 000 3.307zM42.44 31.252a1.654 1.654 0 100-3.307 1.654 1.654 0 000 3.307zM27.559 57.047a1.654 1.654 0 100-3.307 1.654 1.654 0 000 3.307z"
      />
      <path
        fill="#324A5E"
        d="M35 43.075a.682.682 0 01-.689-.69v-10.03c0-.386.303-.69.689-.69.386 0 .689.303.689.69v10.03a.682.682 0 01-.689.69z"
      />
      <path fill="#F1543F" d="M35 44.976a2.59 2.59 0 100-5.18 2.59 2.59 0 000 5.18z" />
      <path fill="#FF7058" d="M52.858 25.134a2.205 2.205 0 100-4.41 2.205 2.205 0 000 4.41z" />
      <path fill="#2C9984" d="M17.142 25.134a2.205 2.205 0 100-4.41 2.205 2.205 0 000 4.41z" />
    </svg>
  );
}

export default Stopwatch;
