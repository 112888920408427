import React, { useState } from 'react';
import Input from '../../ui/Input';
import SuccessPopup from './SuccessPopup';
import { sendMessage } from '../../../actions/home';
import validator from 'validator';

interface Props {
  close: any;
  ymTarget?: any;
  buttonTitle?: string;
}

function ConsultationPopup(props: Props) {
  const { close, ymTarget, buttonTitle = 'Записаться' } = props;
  const [name, toggleName] = useState('');
  const [phone, togglePhone] = useState('');
  const [nameError, toggleNameError] = useState('');
  const [phoneError, togglePhoneError] = useState('');
  const [successRequest, toggleSuccessRequest] = useState(false);
  const [apiError, toggleApiError] = useState('');

  const validateInput = () => {
    let error = false;

    if (!name) {
      toggleNameError('Введите имя');
      error = true;
    } else {
      toggleNameError('');
      error = false;
    }

    return error;
  };

  const validatePhone = () => {
    let error = false;

    if (!validator.isMobilePhone(phone)) {
      error = true;
      togglePhoneError('Неверный формат');
    } else {
      togglePhoneError('');
      error = false;
    }

    return error;
  };

  const replacePhoneNumber = (value: string) => {
    togglePhone(value.replace(/ /g, ''));
  };

  const validate = () => {
    return validateInput() || validatePhone();
  };

  const submit = () => {
    if (validate()) return;
    sendMessage({
      name,
      phone,
    })
      .then(() => {
        // @ts-ignore
        if (ymTarget) {
          ymTarget();
        }
        toggleApiError('');
        toggleSuccessRequest(true);
      })
      .catch((error) => {
        toggleApiError(error);
      });
  };

  const renderForm = () => {
    return (
      <div className="consultation-popup">
        <i className="far fa-2x fa-times-circle global-portal__close" onClick={close} />
        <Input
          value={name}
          onChange={(event: any) => toggleName(event.target.value)}
          placeholder="Имя"
          error={nameError}
        />
        <Input
          value={phone}
          onChange={(event: any) => replacePhoneNumber(event.target.value)}
          placeholder="Телефон"
          error={phoneError}
        />
        {apiError && <p className="error-message">{apiError}</p>}
        <button className="main-button" type="button" onClick={submit}>
          {buttonTitle}
        </button>
      </div>
    );
  };

  return successRequest ? <SuccessPopup close={close} /> : renderForm();
}

export default ConsultationPopup;
