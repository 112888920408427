import React from 'react';

function Lists() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="118" height="105" fill="none">
      <g>
        <path fill="#fff" d="M66.48 10.037l-25.048 58.18L88.955 88.63l25.048-58.18-47.524-20.414z" />
        <path
          fill="#ECECF0"
          d="M102.066 37.56a.47.47 0 01-.631.198L69.808 24.183a.436.436 0 11.33-.801l31.628 13.585a.47.47 0 01.3.593zm-11.775-3.35a.47.47 0 01-.632.198l-20.473-8.793a.437.437 0 01-.182-.745.435.435 0 01.523-.046L90 33.617a.471.471 0 01.291.593zm-5.75-.733a.47.47 0 01-.631.198L68.558 27.08a.437.437 0 01-.183-.745.435.435 0 01.523-.046l15.353 6.594a.471.471 0 01.29.594zm14.455 11.216a.47.47 0 01-.631.198L66.738 31.306a.436.436 0 11.34-.791L98.714 44.1a.471.471 0 01.283.593zm-11.776-3.35a.47.47 0 01-.631.198l-20.46-8.793a.437.437 0 01-.183-.745.435.435 0 01.523-.046l20.463 8.793a.471.471 0 01.288.593zm-5.75-.733a.47.47 0 01-.631.197l-15.352-6.594a.437.437 0 01-.02-.838.437.437 0 01.36.047l15.353 6.594a.471.471 0 01.29.594zm13.96 12.365a.471.471 0 01-.632.198L63.173 39.591a.436.436 0 11.341-.791l31.627 13.584a.471.471 0 01.288.591zm-11.776-3.347a.47.47 0 01-.632.198l-20.47-8.793a.436.436 0 11.34-.791l20.474 8.793a.471.471 0 01.288.593zm-5.744-.734a.471.471 0 01-.632.198l-15.353-6.595a.436.436 0 11.341-.79L77.618 48.3a.47.47 0 01.291.593zm9.754 22.108a.47.47 0 01-.631.198L55.408 57.627a.437.437 0 01-.02-.837.437.437 0 01.361.046l31.627 13.585a.471.471 0 01.288.58zm-11.775-3.338a.471.471 0 01-.632.198L54.784 59.07a.436.436 0 11.341-.79l20.473 8.792a.47.47 0 01.29.593zm-5.75-.734a.47.47 0 01-.632.199l-15.352-6.595a.436.436 0 11.34-.791l15.353 6.595a.471.471 0 01.29.593zm22.247-6.884a.471.471 0 01-.631.197L60.128 46.66a.437.437 0 01-.182-.745.437.437 0 01.523-.047l31.627 13.585a.471.471 0 01.29.594zm-11.776-3.35a.471.471 0 01-.63.198L59.505 48.1a.438.438 0 01-.29-.594.435.435 0 01.631-.197l20.473 8.792a.471.471 0 01.29.594zm-1.95 4.541a.47.47 0 01-.63.198l-20.474-8.793a.436.436 0 11.34-.79l20.474 8.792a.47.47 0 01.29.593zm-3.799-5.275a.472.472 0 01-.631.198l-15.353-6.595a.438.438 0 01-.29-.593.434.434 0 01.456-.26.437.437 0 01.175.062L74.57 55.37a.47.47 0 01.291.593zm-3.441.346a.47.47 0 01-.632.197l-12.575-5.4a.436.436 0 11.34-.791l12.588 5.4a.471.471 0 01.278.594z"
        />
        <path
          fill="#88BA34"
          d="M97.71 41.493a.189.189 0 01-.182-.145l-.552-2.306a.187.187 0 01.11-.218.187.187 0 01.234.076l.814 1.337 3.008-1.002a.188.188 0 01.15.343l-3.489 1.88a.188.188 0 01-.093.035z"
        />
        <path
          fill="#88BA34"
          d="M97.164 39l.892 1.467 3.143-1.06-3.489 1.899L97.16 39h.005zm-.005-.376a.377.377 0 00-.366.463l.551 2.306a.375.375 0 00.547.25l3.474-1.892a.376.376 0 00-.153-.709h-.075a.377.377 0 00-.073.018l-2.852.956-.737-1.209a.377.377 0 00-.321-.18l.005-.003zM83.534 74.432a.189.189 0 01-.183-.145l-.552-2.306a.187.187 0 01.343-.14l.815 1.337 3.008-1.002a.188.188 0 01.233.103.188.188 0 01-.083.25l-3.484 1.898a.185.185 0 01-.097.005z"
        />
        <path
          fill="#88BA34"
          d="M82.982 71.94l.893 1.468 3.143-1.06-3.484 1.909-.552-2.306v-.01zm0-.365a.375.375 0 00-.366.463l.552 2.306a.375.375 0 00.546.25l3.47-1.912a.376.376 0 00-.154-.709h-.075a.37.37 0 00-.073.018l-2.847.961-.732-1.2a.376.376 0 00-.32-.18v.003zm7.913-14.243a.188.188 0 01-.183-.146l-.552-2.305a.188.188 0 01.344-.14l.814 1.336 3.008-1.001a.188.188 0 01.233.102.187.187 0 01-.082.25l-3.484 1.899a.185.185 0 01-.098.005z"
        />
        <path
          fill="#88BA34"
          d="M90.344 54.84l.892 1.468 3.143-1.06-3.484 1.899-.551-2.306zm0-.375a.378.378 0 00-.368.295.375.375 0 00.002.168l.551 2.306a.376.376 0 00.547.25l3.474-1.892a.376.376 0 00-.153-.709h-.076a.372.372 0 00-.072.018l-2.848.961-.737-1.21a.375.375 0 00-.32-.18v-.007z"
        />
        <path
          fill="#13959C"
          d="M66.494 5.884H6.214C4.99 5.884 4 6.874 4 8.094v77.927c0 1.221.99 2.211 2.213 2.211h60.281c1.223 0 2.214-.99 2.214-2.21V8.093c0-1.22-.991-2.21-2.214-2.21z"
        />
        <path
          fill="#24B7C4"
          d="M68.708 83.3a2.17 2.17 0 01-2.213 2.135H6.223A2.178 2.178 0 014.01 83.3V8.02a2.171 2.171 0 012.213-2.136h60.282a2.178 2.178 0 012.213 2.135V83.3h-.01z"
        />
        <path fill="#fff" d="M64.95 10.333H7.758v70.013h57.194V10.332z" />
        <path
          fill="#B4B3B3"
          d="M54.569 6.577a3.27 3.27 0 00-3.277-3.272H21.418a3.278 3.278 0 00-3.276 3.272v5.759h36.427V6.577z"
        />
        <path
          fill="#B4B3B3"
          d="M36.354 10.145a5.075 5.075 0 005.078-5.073A5.075 5.075 0 0036.354 0a5.075 5.075 0 00-5.078 5.072 5.075 5.075 0 005.078 5.073z"
        />
        <path fill="#EDEFC7" d="M36.354 5.072a1.556 1.556 0 10.002-3.11 1.556 1.556 0 00-.002 3.11z" />
        <path fill="#D2D2D2" d="M54.566 8.327H18.139v4.009h36.427V8.327z" />
        <path
          fill="#ECECF0"
          d="M54.957 25.51a.5.5 0 01-.501.476H16.289a.477.477 0 01-.513-.477.477.477 0 01.513-.477h38.167a.502.502 0 01.5.478zm-13.425 1.735a.5.5 0 01-.501.476H16.289a.478.478 0 110-.954h24.742a.502.502 0 01.501.478zm-6.161 1.763a.5.5 0 01-.501.475H16.29a.478.478 0 110-.954h18.582a.501.501 0 01.5.479zm19.35 7.776H24.616a.501.501 0 00-.501.5v.477a.5.5 0 00.501.5h30.105a.501.501 0 00.502-.5v-.476a.5.5 0 00-.502-.5zm0 7.438H24.616a.501.501 0 00-.501.501v.476a.5.5 0 00.501.5h30.105a.501.501 0 00.502-.5v-.476c0-.276-.225-.5-.502-.5zm0 7.572H24.616a.501.501 0 00-.501.5v.476a.5.5 0 00.501.5h30.105a.5.5 0 00.502-.5v-.476a.5.5 0 00-.502-.5zm0 7.062H24.616a.501.501 0 00-.501.501v.476a.5.5 0 00.501.5h30.105a.501.501 0 00.502-.5v-.476c0-.276-.225-.5-.502-.5zM21.012 34.854v4.634h-4.635v-4.634h4.635zm.589-.589h-5.813v5.814h5.82v-5.814h-.007zm-.589 8.125v4.634h-4.635V42.39h4.635zm.589-.589h-5.813v5.814h5.82v-5.814h-.007zm-.589 8.117v4.635h-4.635v-4.635h4.635zm.589-.588h-5.813v5.814h5.82V49.33h-.007zm-.589 8.212v4.634h-4.635v-4.634h4.635zm.589-.588h-5.813v5.813h5.82v-5.813h-.007z"
        />
        <path
          fill="#88BA34"
          d="M18.723 38.762a.188.188 0 01-.148-.075l-1.917-2.572a.188.188 0 01.25-.265l1.772 1.252 3.226-2.894a.19.19 0 01.243.01.188.188 0 01.03.24l-3.313 4.221a.189.189 0 01-.148.073l.005.01z"
        />
        <path
          fill="#88BA34"
          d="M22.04 34.355l-3.314 4.222-1.918-2.572 1.895 1.347 3.339-3.004-.003.008zm.002-.383a.374.374 0 00-.25.098l-3.114 2.794-1.652-1.164a.376.376 0 00-.518.53l1.917 2.572a.376.376 0 00.46.113.375.375 0 00.131-.105l3.307-4.21a.376.376 0 00-.286-.617l.005-.01zm-3.319 11.743a.187.187 0 01-.148-.076l-1.917-2.57a.188.188 0 01.25-.266l1.772 1.252 3.226-2.895a.189.189 0 01.302.125.188.188 0 01-.028.126l-3.314 4.221a.187.187 0 01-.148.073l.005.01z"
        />
        <path
          fill="#88BA34"
          d="M22.04 41.3l-3.314 4.222-1.918-2.561 1.895 1.347 3.339-3.005-.003-.002zm.002-.372a.374.374 0 00-.25.097l-3.114 2.794-1.65-1.171a.376.376 0 00-.518.53l1.917 2.572a.377.377 0 00.296.15.377.377 0 00.296-.143l3.306-4.208a.375.375 0 00-.286-.619l.003-.002z"
        />
        <path fill="#DDDDE0" d="M7.757 63.17v17.095h8.327c-.366 0-7.823-8.512-8.327-17.095z" />
        <path
          fill="#ECECF0"
          d="M17.15 72.181c-2.47-.736-4.292-1.753-5.638-2.897a10.269 10.269 0 01-3.76-7.303v1.117c.502 8.592 7.961 17.172 8.327 17.17a2.732 2.732 0 01-.572-1.718c-.04-1.582.81-3.75 1.642-6.369z"
        />
        <path
          fill="#B0B1B3"
          d="M65.993 70.296h-1.724v-2.2h1.724v2.2zM75.8 74.45l-.754-.752 1.454-1.452.755.754-1.454 1.45zm-20.928.287l.754-.753-1.752-1.748-.752.754 1.75 1.747z"
        />
        <path
          fill="#BDBEC0"
          d="M65.993 66.09h-1.526c-.562 0-1.018.455-1.018 1.016v.706c0 .562.456 1.017 1.018 1.017h1.526c.562 0 1.018-.455 1.018-1.017v-.706c0-.561-.456-1.016-1.018-1.016zm10.535 5.413l-.308.308a.628.628 0 000 .889l.668.667a.63.63 0 00.89 0l.308-.308a.628.628 0 000-.889l-.668-.667a.63.63 0 00-.89 0zM52.965 71.5l-.668.667a.628.628 0 000 .889l.308.308a.63.63 0 00.89 0l.668-.668a.628.628 0 000-.888l-.308-.309a.63.63 0 00-.89 0z"
        />
        <path
          fill="#D1D2D4"
          d="M67.432 63.691h-4.577c-.562 0-1.018.455-1.018 1.017v1.44c0 .56.456 1.016 1.018 1.016h4.577c.562 0 1.017-.455 1.017-1.017v-1.44c0-.56-.455-1.016-1.017-1.016zm9.445 6.059l-.63.63a.628.628 0 000 .889l2.002 2a.63.63 0 00.89 0l.63-.63a.628.628 0 000-.889l-2.002-2a.63.63 0 00-.89 0zm-24.257-.005l-2.003 2a.628.628 0 000 .89l.63.63a.63.63 0 00.89 0l2.003-2.001a.628.628 0 000-.889l-.63-.63a.63.63 0 00-.89 0zM65.259 97c7.585 0 13.733-6.142 13.733-13.718s-6.148-13.717-13.733-13.717c-7.585 0-13.733 6.141-13.733 13.717C51.526 90.858 57.674 97 65.259 97z"
        />
        <path
          fill="#BDBEC0"
          d="M65.259 94.156c6.012 0 10.885-4.868 10.885-10.874 0-6.005-4.873-10.873-10.885-10.873s-10.886 4.868-10.886 10.873c0 6.006 4.874 10.874 10.886 10.874z"
        />
        <path
          fill="#fff"
          d="M65.259 92.824c5.275 0 9.552-4.272 9.552-9.542s-4.277-9.541-9.552-9.541c-5.276 0-9.553 4.272-9.553 9.541 0 5.27 4.277 9.542 9.553 9.542z"
        />
        <path
          fill="#B0B1B3"
          d="M65.442 75.053h-.236a.095.095 0 00-.095.095v1.242c0 .052.042.095.095.095h.236a.095.095 0 00.095-.095v-1.242a.095.095 0 00-.095-.095zm0 14.799h-.236a.095.095 0 00-.095.095v1.242c0 .053.042.095.095.095h.236a.095.095 0 00.095-.095v-1.242a.095.095 0 00-.095-.095zm3.869-13.659l-.646 1.06a.095.095 0 00.032.132l.201.122a.095.095 0 00.131-.032l.646-1.06a.095.095 0 00-.031-.132l-.202-.122a.095.095 0 00-.13.032zm-7.697 12.641l-.646 1.06a.095.095 0 00.032.131l.201.123a.095.095 0 00.131-.032l.646-1.061a.095.095 0 00-.032-.13l-.201-.123a.095.095 0 00-.131.032zm10.561-9.839l-1.078.619a.095.095 0 00-.035.13l.118.204a.095.095 0 00.13.035l1.077-.62a.095.095 0 00.035-.129l-.117-.204a.095.095 0 00-.13-.035zM59.34 86.35l-1.078.619a.095.095 0 00-.035.13l.117.204a.095.095 0 00.13.035l1.078-.62a.095.095 0 00.035-.13l-.117-.203a.095.095 0 00-.13-.035zm12.68-3.27l-.001.235c0 .052.042.095.095.095l1.243.005a.095.095 0 00.096-.095v-.236a.095.095 0 00-.095-.095l-1.243-.004a.095.095 0 00-.095.095zm-14.82-.056v.235c0 .052.042.095.095.095l1.243.005a.095.095 0 00.096-.095v-.235a.095.095 0 00-.094-.096l-1.244-.004a.095.095 0 00-.095.095zm13.968 3.386l-.118.203a.095.095 0 00.035.13l1.076.622c.045.026.104.01.13-.035l.118-.204a.095.095 0 00-.035-.13l-1.076-.621a.095.095 0 00-.13.034zm-12.814-7.394l-.118.203a.095.095 0 00.034.13l1.076.623c.046.026.104.01.13-.035l.118-.203a.095.095 0 00-.034-.13l-1.076-.623a.095.095 0 00-.13.035z"
        />
        <path
          fill="#B0B1B3"
          d="M71.168 86.41l-.118.203a.095.095 0 00.035.13l1.076.622c.045.026.104.01.13-.035l.118-.204a.095.095 0 00-.035-.13l-1.076-.621a.095.095 0 00-.13.034zm-12.814-7.394l-.118.203a.095.095 0 00.034.13l1.076.623c.046.026.104.01.13-.035l.118-.203a.095.095 0 00-.034-.13l-1.076-.623a.095.095 0 00-.13.035zm10.456 9.86l-.203.12a.095.095 0 00-.034.13l.63 1.07c.026.046.084.06.13.034l.203-.119a.095.095 0 00.034-.13l-.63-1.071a.095.095 0 00-.13-.034zm-7.5-12.762l-.203.12a.095.095 0 00-.034.13l.629 1.07a.095.095 0 00.13.035l.203-.12a.095.095 0 00.034-.13l-.629-1.07a.095.095 0 00-.13-.035zm4.114 7.646a.626.626 0 100-1.252.626.626 0 000 1.252z"
        />
        <path stroke="#B0B1B3" strokeLinecap="round" strokeLinejoin="round" d="M63.697 84.276l7.084-4.306" />
      </g>
      <defs>
        <filter
          id="filter0_d"
          width="118"
          height="105"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default Lists();
