import React from 'react';
import { Lecturer } from '../Lecturers';

type Props = {
  lecture: Lecturer;
  close: any;
  renderCourses: any;
};

function LecturerPopup(props: Props) {
  const {
    lecture: {
      name: { firstName, lastName },
      icon,
      experience,
      education,
      courses,
      description,
      hobbies,
    },
    close,
    renderCourses,
  } = props;

  return (
    <div className="lecturer-popup">
      <i className="far fa-2x fa-times-circle global-portal__close" onClick={close} />
      <div className="lecturer-popup-row lecturer-popup-row_center">
        <img className="lecturer__icon" src={icon} alt="lecture" />
      </div>
      <div className="lecturer-popup-row lecturer-popup-row_center">
        <div className="lecturer__name-block">
          <h3 className="lecturer__name">
            {firstName} {lastName}
          </h3>
        </div>
      </div>
      <div className="lecturer-popup-row">
        <div className="lecturer-popup-block">
          <p className="lecturer__text">Преподавательский стаж</p>
          <p className="lecturer__experience">{experience}</p>
          <p className="lecturer__text">Образование</p>
          <p className="lecturer__education">{education}</p>
          <p className="lecturer__text">Преподает на курсах</p>
          <div className="lecturer-courses">{renderCourses(courses)}</div>
        </div>
        <div className="lecturer-popup-block">
          <p className="lecturer-popup-block__hobbies-text">{description}</p>
          <h4 className="lecturer-popup-block__hobbies-title">Увлечения и интересы:</h4>
          <p className="lecturer-popup-block__hobbies-text">{hobbies}</p>
        </div>
      </div>
    </div>
  );
}

export default LecturerPopup;
