import React, { useState, useEffect } from 'react';
import { Link } from '../home/Main';
import { NavLink } from 'react-router-dom';

type Props = {
  links: Array<Link>;
};

function BurgerMenu(props: Props) {
  const { links } = props;
  const [isOpenMenu, toggleOpenMenu] = useState(false);
  const [activeElem, toggleActiveElem] = useState('');

  const scroll = (event: any, to: string) => {
    event.preventDefault();
    const selector = `#${to}`;
    const element = document.querySelector(selector);
    if (!element) return;
    toggleOpenMenu(false);
    toggleActiveElem(selector);
    window.history.replaceState(null, '', `/${selector}`);
    element.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    toggleActiveElem(window.location.hash);
  }, []);

  const renderLinks = () => {
    return links.map((link) => {
      const { name, to } = link;
      const active = () => activeElem === `#${to}`;
      return (
        <li key={name}>
          <NavLink
            isActive={active}
            className="burger-list__link"
            onClick={(event) => scroll(event, to)}
            to={`/#${to}`}
          >
            {name}
          </NavLink>
        </li>
      );
    });
  };

  return (
    <>
      <div className={`burger-menu ${isOpenMenu ? 'active' : ''}`} onClick={() => toggleOpenMenu(!isOpenMenu)}>
        <span />
        <span />
        <span />
      </div>
      {isOpenMenu && <ul className="burger-list">{renderLinks()}</ul>}
    </>
  );
}

export default BurgerMenu;
