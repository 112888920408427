import React, { useState } from 'react';
import banner from '../../assets/images/background.jpg';
import GlobalPortal from '../ui/GlobalPortal';
import ConsultationPopup from './popups/ConsultationPopup';

function Banner() {
  const [openBanner, toggleBanner] = useState(false);

  return (
    <div className="banner">
      <img className="banner-image" src={banner} alt="banner" />
      <div className="banner-image-mobile" />
      <div className="banner-form">
        <h1 className="banner__title">
          Время превращать
          <br />
          увлечение
          <br /> в профессию!
        </h1>
        <p className="banner__description">Курсы по программированию для детей от 10 лет</p>
        <button className="main-button" type="button" onClick={() => toggleBanner(true)}>
          Записаться на бесплатный урок
        </button>
      </div>
      {openBanner && (
        <GlobalPortal close={() => toggleBanner(false)}>
          <ConsultationPopup
            close={() => toggleBanner(false)}
            ymTarget={() => {
              //@ts-ignore
              window.ym(67016755, 'reachGoal', 'freeLesson');
            }}
          />
        </GlobalPortal>
      )}
    </div>
  );
}

export default Banner;
