import React, { useState } from 'react';
import GlobalModal from '../ui/GlobalModal';

const CitySelect = (props: any) => {
  const { cities, currentCity, toggleCurrentCity } = props;

  const visiblePhone = currentCity.phones[1];

  const [isListOpen, toggleCitiesList] = useState(false);

  const changeCity = (city: object) => {
    toggleCitiesList(false);
    toggleCurrentCity(city);
  };

  const renderCities = () => {
    return cities.map((city: any, index: any) => {
      return (
        <li key={index} className="toggle-list__item" onClick={() => changeCity(city)}>
          <i className="fas fa-map-marker-alt custom-select__marker" />
          <p className="custom-select__text">{city.name}</p>
        </li>
      );
    });
  };

  return (
    <div className="custom-select-wrapper">
      <button type="button" className="custom-select" onClick={() => toggleCitiesList(!isListOpen)}>
        <i className="fas fa-map-marker-alt custom-select__marker" />
        <p className="custom-select__text">{currentCity.name}</p>
        <i className="fas fa-chevron-down custom-select__icon" />
        {isListOpen && (
          <GlobalModal close={() => {}}>
            <ul className="toggle-list">{renderCities()}</ul>
          </GlobalModal>
        )}
      </button>
      <a href={`tel:${visiblePhone.replace(/ /g, '')}`} className="custom-select__phone">
        {visiblePhone}
      </a>
    </div>
  );
};

export default CitySelect;
