import React from 'react';

interface Props {
  value: string;
  onChange: any;
  className?: string;
  placeholder?: string;
  error?: string;
}

function Textarea(props: Props) {
  const { value, onChange, className = '', placeholder = '', error = '' } = props;
  return (
    <div className={`main-input-wrapper ${className} ${error ? 'main-input-has-error' : ''}`}>
      <textarea className="main-textarea" placeholder={placeholder} value={value} onChange={onChange} />
      {error && <div className="main-input-error">{error}</div>}
    </div>
  );
}

export default Textarea;
