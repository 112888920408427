import React from 'react';
import { Course } from '../OurCourses';
import Stopwatch from '../../icons/Stopwatch';
import Calendar from '../../icons/Calendar';
import Card from '../../icons/Card';
import Contact from '../../icons/Contact';

type Props = {
  course: Course;
  close: any;
  renderDescription: any;
  renderSkills: any;
};

function CoursePopup(props: Props) {
  const {
    course: { icon, title, forWhom, skills, lessons, courseTime, price, documents, next },
    close,
    renderDescription,
    renderSkills,
  } = props;

  const renderDetailedBlockDescription = (array: Array<string>) => {
    return array.map((key) => {
      return (
        <p key={key} className="course-popup-detailed-block__description">
          {key}
        </p>
      );
    });
  };

  return (
    <div className="course-popup">
      <i className="far fa-2x fa-times-circle global-portal__close" onClick={close} />
      <div className="course-popup-header">
        <h3 className="our-course__title_white">{title}</h3>
        <div className="course-popup-header__icon">{icon}</div>
      </div>
      <div className="course-popup-body">
        <div className="our-course-whom">
          <h4 className="our-course-whom__title">Для кого?</h4>
        </div>
        <div className="our-course-descriptions">{renderDescription(forWhom)}</div>
        <div className="our-course-whom">
          <h4 className="our-course-whom__title">Чему научим?</h4>
        </div>
        <div className="our-course-skills">{renderSkills(skills)}</div>
        <div className="course-popup-detailed">
          <div className="course-popup-detailed-block">
            <div className="course-popup-detailed-block-icon">
              <Stopwatch />
            </div>
            <div className="course-popup-detailed-block-desc">
              <h4 className="course-popup-detailed-block__title">Занятия</h4>
              <div className="course-popup-detailed-block-descriptions">{renderDetailedBlockDescription(lessons)}</div>
            </div>
          </div>
          <div className="course-popup-detailed-block">
            <div className="course-popup-detailed-block-icon">
              <Calendar />
            </div>
            <div className="course-popup-detailed-block-desc">
              <h4 className="course-popup-detailed-block__title">Курс</h4>
              <div className="course-popup-detailed-block-descriptions">
                {renderDetailedBlockDescription(courseTime)}
              </div>
            </div>
          </div>
        </div>
        <div className="course-popup-detailed">
          <div className="course-popup-detailed-block">
            <div className="course-popup-detailed-block-icon">
              <Card />
            </div>
            <div className="course-popup-detailed-block-desc">
              <h4 className="course-popup-detailed-block__title">Стоимость</h4>
              <p className="course-popup-detailed-block__price">{price}</p>
            </div>
          </div>
          <div className="course-popup-detailed-block">
            <div className="course-popup-detailed-block-icon">
              <Contact />
            </div>
            <div className="course-popup-detailed-block-desc">
              <h4 className="course-popup-detailed-block__title">Документы</h4>
              <div className="course-popup-detailed-block-descriptions">
                <h4 className="course-popup-detailed-block__documents">{documents}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="our-course-whom">
          <h4 className="our-course-whom__title">Куда дальше?</h4>
        </div>
        <div className="our-course-whom">
          <p className="our-course__description">{next}</p>
        </div>
      </div>
    </div>
  );
}

export default CoursePopup;
