import React from 'react';

function Calendar() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="none">
      <path fill="#45B39C" d="M35 70c19.33 0 35-15.67 35-35S54.33 0 35 0 0 15.67 0 35s15.67 35 35 35z" />
      <path fill="#E1E6E9" d="M55.508 26.294H14.492v29.214h41.016V26.294z" />
      <path fill="#EBF0F3" d="M55.508 25.216H14.492v29.215h41.016V25.217z" />
      <path fill="#E1E6E9" d="M55.508 25.216H14.492v.943h41.016v-.942z" />
      <path fill="#E56353" d="M55.508 18.488H14.492v6.729h41.016v-6.729z" />
      <path fill="#CE412D" d="M19.995 22.873a1.283 1.283 0 100-2.565 1.283 1.283 0 000 2.566z" />
      <path
        fill="#E1E6E9"
        d="M19.995 14.492a.856.856 0 00-.856.856V21.6a.856.856 0 101.712 0v-6.252a.856.856 0 00-.856-.856z"
      />
      <path fill="#CE412D" d="M25.6 22.873a1.283 1.283 0 100-2.565 1.283 1.283 0 000 2.566z" />
      <path
        fill="#E1E6E9"
        d="M25.6 14.492a.856.856 0 00-.855.856V21.6a.856.856 0 001.712 0v-6.252a.856.856 0 00-.856-.856z"
      />
      <path fill="#CE412D" d="M44.4 22.873a1.283 1.283 0 100-2.565 1.283 1.283 0 000 2.566z" />
      <path
        fill="#E1E6E9"
        d="M44.4 14.492a.856.856 0 00-.856.856V21.6a.856.856 0 101.712 0v-6.252a.856.856 0 00-.856-.856z"
      />
      <path fill="#CE412D" d="M50.006 22.873a1.283 1.283 0 100-2.565 1.283 1.283 0 000 2.566z" />
      <path
        fill="#E1E6E9"
        d="M50.005 14.492a.856.856 0 00-.856.856V21.6a.856.856 0 001.712 0v-6.252a.856.856 0 00-.856-.856z"
      />
      <path fill="#44C4A1" d="M40.249 28.558h-3.98v3.98h3.98v-3.98z" />
      <path fill="#64798A" d="M46.768 28.558h-3.98v3.98h3.98v-3.98z" />
      <path fill="#E56353" d="M53.286 28.558h-3.98v3.98h3.98v-3.98z" />
      <path
        fill="#64798A"
        d="M20.692 35.29h-3.98v3.98h3.98v-3.98zm6.52 0h-3.98v3.98h3.98v-3.98zm6.518 0h-3.98v3.98h3.98v-3.98z"
      />
      <path fill="#44C4A1" d="M40.249 35.29h-3.98v3.98h3.98v-3.98z" />
      <path fill="#64798A" d="M46.768 35.29h-3.98v3.98h3.98v-3.98z" />
      <path fill="#E56353" d="M53.286 35.29h-3.98v3.98h3.98v-3.98z" />
      <path
        fill="#64798A"
        d="M20.692 42.022h-3.98v3.98h3.98v-3.98zm6.52 0h-3.98v3.98h3.98v-3.98zm6.518 0h-3.98v3.98h3.98v-3.98z"
      />
      <path fill="#44C4A1" d="M40.249 42.022h-3.98v3.98h3.98v-3.98z" />
      <path fill="#64798A" d="M46.768 42.022h-3.98v3.98h3.98v-3.98z" />
      <path fill="#E56353" d="M53.286 42.022h-3.98v3.98h3.98v-3.98z" />
      <path
        fill="#64798A"
        d="M20.692 48.754h-3.98v3.98h3.98v-3.98zm6.52 0h-3.98v3.98h3.98v-3.98zm6.518 0h-3.98v3.98h3.98v-3.98z"
      />
      <path fill="#44C4A1" d="M40.249 48.754h-3.98v3.98h3.98v-3.98z" />
    </svg>
  );
}

export default Calendar;
