import React from 'react';

function Coding() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="152" height="152" fill="none">
      <path
        className="our-course__icon"
        d="M76 152c41.974 0 76-34.026 76-76S117.974 0 76 0 0 34.026 0 76s34.026 76 76 76z"
      />
      <path
        fill="#0770B0"
        d="M130.193 30.508H24.185c-.965 0-1.748.783-1.748 1.748v75.842c0 .966.783 1.748 1.748 1.748h106.008c.966 0 1.749-.782 1.749-1.748V32.256c0-.965-.783-1.748-1.749-1.748z"
      />
      <path
        fill="#1C1C1C"
        d="M129.3 109.084H24.96a1.706 1.706 0 01-1.708-1.709V36.633h107.783v70.742a1.699 1.699 0 01-.51 1.218 1.719 1.719 0 01-1.225.491z"
      />
      <path fill="#000" d="M129.418 38.71l-106.166-.88v-1.197h107.783v70.742c-.027.947-1.617-68.665-1.617-68.665z" />
      <path
        fill="#0A98ED"
        d="M77.301 31.98c29.669 0 53.721-.182 53.721-.407 0-.226-24.052-.408-53.72-.408-29.67 0-53.721.183-53.721.408 0 .225 24.051.407 53.72.407z"
      />
      <path
        fill="#76A9AB"
        d="M29.535 42.39l-.683.684 1.235 1.222-1.235 1.223.683.67 1.893-1.893-1.893-1.906zm2.655 4.666l-.67.67 1.222 1.223-1.222 1.223.67.683 1.906-1.906-1.906-1.893zm-2.655 9.306l-.683.684 1.235 1.223-1.235 1.222.683.67 1.893-1.892-1.893-1.907zm3.983-4.652l-.67.67 1.222 1.222-1.222 1.223.67.683 1.906-1.906-1.906-1.892zm0 9.306l-.67.683 1.222 1.223-1.222 1.222.67.67 1.906-1.892-1.906-1.906z"
      />
      <path fill="#8A8A8A" d="M50.75 43.494H35.187v1.604H50.75v-1.604z" />
      <path fill="#fff" d="M78.011 43.494H62.448v1.604h15.563v-1.604z" />
      <path
        fill="#8A8A8A"
        d="M65.117 48.148H36.423v1.603h28.694v-1.603zm30.244 0H66.667v1.603h28.694v-1.603zm17.325 4.652H83.992v1.604h28.694V52.8z"
      />
      <path fill="#fff" d="M60.07 57.453H32.518v1.604h27.55v-1.604z" />
      <path fill="#8A8A8A" d="M81.008 52.8H37.29v1.604h43.718V52.8z" />
      <path fill="#FFBC4F" d="M115.446 57.453H71.728v1.604h43.718v-1.604z" />
      <path fill="#fff" d="M110.872 62.12H67.154v1.603h43.718V62.12zm-45.755 0H36.423v1.603h28.694V62.12z" />
      <path fill="#FFBC4F" d="M59.727 43.494H52.67v1.604h7.058v-1.604z" />
      <path fill="#fff" d="M87.514 43.494h-7.058v1.604h7.058v-1.604zm9.609 0h-7.059v1.604h7.059v-1.604z" />
      <path fill="#8A8A8A" d="M104.182 48.148h-7.059v1.603h7.059v-1.603z" />
      <path fill="#FFBC4F" d="M121.624 52.8h-7.059v1.604h7.059V52.8z" />
      <path fill="#8A8A8A" d="M69.507 57.453h-7.059v1.604h7.059v-1.604zm43.612-9.305h-7.058v1.603h7.058v-1.603z" />
      <path
        fill="#76A9AB"
        d="M29.535 65.734l-.683.67 1.235 1.223-1.235 1.223.683.67 1.893-1.893-1.893-1.893zm2.655 4.654l-.67.67 1.222 1.222-1.222 1.223.67.683 1.906-1.906-1.906-1.892zm-2.655 9.306l-.683.683 1.235 1.223-1.235 1.222.683.67 1.893-1.892-1.893-1.906zm3.983-4.654l-.67.67 1.222 1.223-1.222 1.236.67.67 1.906-1.906-1.906-1.893zm0 9.32l-.67.67 1.222 1.223-1.222 1.222.67.67 1.906-1.892-1.906-1.893z"
      />
      <path fill="#8A8A8A" d="M50.75 66.825H35.187v1.604H50.75v-1.604zm27.261 0H62.448v1.604h15.563v-1.604z" />
      <path fill="#FF9940" d="M65.117 71.478H36.423v1.604h28.694v-1.604z" />
      <path
        fill="#8A8A8A"
        d="M95.361 71.478H66.667v1.604h28.694v-1.604zm17.325 4.653H83.992v1.604h28.694V76.13zM60.07 80.798H32.518V82.4h27.55v-1.603zm20.938-4.667H37.29v1.604h43.718V76.13z"
      />
      <path fill="#FF9940" d="M115.446 80.798H71.728V82.4h43.718v-1.603z" />
      <path fill="#fff" d="M110.872 85.45H67.154v1.604h43.718v-1.603z" />
      <path
        fill="#8A8A8A"
        d="M65.117 85.45H36.423v1.604h28.694v-1.603zm-5.39-18.625H52.67v1.604h7.058v-1.604zm27.787 0h-7.058v1.604h7.058v-1.604zm9.609 0h-7.059v1.604h7.059v-1.604zm7.059 4.653h-7.059v1.604h7.059v-1.604zm17.442 4.653h-7.059v1.604h7.059V76.13z"
      />
      <path fill="#fff" d="M69.507 80.798h-7.059V82.4h7.059v-1.603z" />
      <path
        fill="#76A9AB"
        d="M29.535 93.495l-.683.67 1.235 1.223-1.235 1.222.683.684 1.893-1.906-1.893-1.893zm3.983-4.653l-.67.67 1.222 1.223-1.222 1.222.67.67 1.906-1.892-1.906-1.893zm0 9.306l-.67.684 1.222 1.222-1.222 1.222.67.671 1.906-1.893-1.906-1.906z"
      />
      <path fill="#8A8A8A" d="M112.686 89.933H83.992v1.603h28.694v-1.603z" />
      <path fill="#FF9940" d="M60.07 94.586H32.518v1.603h27.55v-1.603z" />
      <path fill="#8A8A8A" d="M81.008 89.933H37.29v1.603h43.718v-1.603zm34.438 4.653H71.728v1.603h43.718v-1.603z" />
      <path fill="#fff" d="M110.872 99.239H67.154v1.604h43.718v-1.604z" />
      <path fill="#8A8A8A" d="M65.117 99.239H36.423v1.604h28.694v-1.604zm56.507-9.306h-7.059v1.603h7.059v-1.603z" />
      <path fill="#FF9940" d="M69.507 94.586h-7.059v1.603h7.059v-1.603z" />
      <path fill="#8A8A8A" d="M113.119 71.478h-7.058v1.604h7.058v-1.604z" />
      <path fill="#05507D" d="M112.462 35.542a1.485 1.485 0 10.001-2.97 1.485 1.485 0 00-.001 2.97z" />
      <path fill="#FF1919" d="M112.462 35.148a1.09 1.09 0 100-2.182 1.09 1.09 0 000 2.182z" />
      <path fill="#05507D" d="M117.759 35.542a1.485 1.485 0 10.001-2.97 1.485 1.485 0 00-.001 2.97z" />
      <path fill="#FF6A19" d="M117.759 35.148a1.09 1.09 0 100-2.182 1.09 1.09 0 000 2.182z" />
      <path fill="#05507D" d="M123.057 35.542a1.485 1.485 0 100-2.97 1.485 1.485 0 000 2.97z" />
      <path fill="#FFBA19" d="M123.056 35.148a1.09 1.09 0 100-2.182 1.09 1.09 0 000 2.182z" />
      <path fill="#1B79B3" d="M83.019 86.213H7.624v52.117h75.395V86.213z" />
      <path fill="#26A9FA" d="M82.151 86.713H8.478v.84h73.674v-.84z" />
      <path fill="#fff" d="M82.151 91.563H8.478v45.676h73.674V91.563z" />
      <path fill="#1C1C1C" d="M43.218 96.925H12.724v35.267h30.494V96.925z" />
      <path
        fill="#C9C9C9"
        d="M79.47 96.925H45.23v1.591h34.24v-1.59zm0 3.405H45.23v1.591h34.24v-1.591zm-54.43 0H14.893v1.591H25.04v-1.591zm6.401 6.795H14.893v1.591H31.44v-1.591zm.079 10.214H14.97v1.59h16.55v-1.59zm0 10.173H14.97v1.591h16.55v-1.591z"
      />
      <path
        fill="#FF9940"
        d="M26.144 113.948H14.97v1.59h11.173v-1.59zm15.024-6.823h-7.663v1.591h7.663v-1.591zm0 10.214h-7.663v1.59h7.663v-1.59z"
      />
      <path fill="#C9C9C9" d="M41.18 120.743h-5.204v1.59h5.205v-1.59zm-.012 6.769h-7.992v1.591h7.992v-1.591z" />
      <path fill="#FF9940" d="M34.096 103.734H14.893v1.591h19.203v-1.591zm0 17.009H14.893v1.59h19.203v-1.59z" />
      <path
        fill="#C9C9C9"
        d="M22.832 110.53h-7.94v1.59h7.94v-1.59zm18.336 0h-16.68v1.59h16.68v-1.59zm-18.336 13.604h-7.94v1.591h7.94v-1.591zm18.336 0h-16.68v1.591h16.68v-1.591zm-3.575-23.804H27.445v1.591h10.148v-1.591zm41.877 3.404H45.23v1.591h34.24v-1.591zm0 3.391H45.23v1.591h34.24v-1.591zm-16.049 3.405H45.23v1.59h18.19v-1.59zm16.049 6.809H45.23v1.59h34.24v-1.59zm-16.049 3.404H45.23v1.59h18.19v-1.59zm0 3.391H45.23v1.591h18.19v-1.591zm0 3.378H45.23v1.591h18.19v-1.591zm0 3.378H45.23v1.591h18.19v-1.591z"
      />
      <path fill="#121212" d="M80.499 11.64l-9.004.042.013 2.76 9.003-.04-.012-2.761z" />
      <path
        fill="#4995A6"
        d="M100.264 23.37l-15.22.066a2.813 2.813 0 01-2.827-2.8l-1.682-9.016a2.812 2.812 0 012.8-2.827l16.863-.078a2.814 2.814 0 012.826 2.8v9.03a2.813 2.813 0 01-2.76 2.825z"
      />
      <path
        fill="#5FC0D6"
        d="M100.264 23.37L83.334 8.807l16.865-.08a2.812 2.812 0 012.826 2.8v9.03a2.81 2.81 0 01-2.761 2.814z"
      />
      <path
        fill="#fff"
        d="M101.057 13.178c.346-.253.223-1.009-.274-1.688-.497-.68-1.18-1.026-1.526-.773-.346.253-.223 1.009.274 1.689.497.68 1.181 1.025 1.526.772z"
      />
      <path
        fill="#242424"
        d="M100.264 24.435l-15.22.066A3.864 3.864 0 0181.1 20.65l-1.67-8.833a3.942 3.942 0 013.825-4.075l16.864-.079a3.872 3.872 0 012.779 1.1 3.87 3.87 0 011.165 2.752v9.03a3.866 3.866 0 01-3.799 3.89zM83.334 9.871a1.762 1.762 0 00-1.748 1.762l1.67 8.833a1.826 1.826 0 001.787 1.932l15.221-.066a1.76 1.76 0 001.748-1.761v-9.03a1.758 1.758 0 00-1.761-1.748l-16.917.078z"
      />
      <path
        fill="#4995A6"
        d="M51.92 23.594l15.22-.066a2.813 2.813 0 002.8-2.826l1.604-9.03a2.814 2.814 0 00-2.826-2.8l-16.864.08a2.813 2.813 0 00-2.8 2.825v9.03a2.814 2.814 0 002.866 2.787z"
      />
      <path
        fill="#5FC0D6"
        d="M67.128 23.515a2.811 2.811 0 002.8-2.826l1.603-9.03a2.811 2.811 0 00-2.826-2.8l-16.864.079 15.287 14.577z"
      />
      <path
        fill="#242424"
        d="M67.14 24.58l-15.22.066a3.864 3.864 0 01-3.944-3.852v-9.03a3.863 3.863 0 013.839-3.943l16.864-.079a3.864 3.864 0 013.943 3.851l-1.617 9.201a3.811 3.811 0 01-3.864 3.786zM51.855 9.977a1.761 1.761 0 00-1.748 1.76v9.031a1.76 1.76 0 001.761 1.748l15.221-.066a1.76 1.76 0 001.748-1.76l1.617-9.202a1.762 1.762 0 00-1.774-1.564l-16.825.053z"
      />
      <path
        fill="#fff"
        d="M69.55 13.118c.347-.252.224-1.008-.274-1.688-.497-.68-1.18-1.025-1.526-.772-.346.253-.223 1.009.275 1.688.497.68 1.18 1.025 1.526.772z"
      />
      <path
        fill="#121212"
        d="M107.52 15.155l-3.431.184-.013-2.865 3.483.144-.039 2.537zm-63.027.276l3.576-.013v-2.865l-3.628.341.052 2.537z"
      />
      <path
        fill="#05588A"
        d="M109.715 107.835l-1.038 5.534 5.744 1.091c.053 1.401.342 2.783.854 4.088l-4.876 3.286 3.167 4.653 4.837-3.299a12.504 12.504 0 003.497 2.287l-.986 5.744 5.534 1.038 1.091-5.744a12.486 12.486 0 004.088-.854l3.299 4.837 4.653-3.168-3.299-4.837a12.484 12.484 0 002.287-3.496l5.744 1.091 1.038-5.534-5.744-1.091a12.486 12.486 0 00-.854-4.088l4.837-3.299-3.168-4.653-4.837 3.299a12.491 12.491 0 00-3.496-2.287l1.091-5.744-5.534-1.038-1.091 5.744a12.434 12.434 0 00-4.088.854l-3.299-4.837-4.653 3.168 3.299 4.837a12.513 12.513 0 00-2.287 3.496l-5.81-1.078zm16.996-.078a6.213 6.213 0 016.453 5.637 6.214 6.214 0 01-.499 3.077 6.159 6.159 0 01-5.402 3.694 6.218 6.218 0 01-5.955-8.715 6.155 6.155 0 015.403-3.693zm-32.914 19.427l-.42 2.195 2.287.434c.022.554.138 1.1.341 1.616l-1.919 1.315 1.315 1.84 1.919-1.314c.41.378.88.685 1.393.906l-.434 2.288 2.195.42.434-2.287a4.951 4.951 0 001.63-.342l1.314 1.919 1.841-1.314-1.315-1.919c.379-.41.685-.881.907-1.393l2.287.433.421-2.195-2.287-.433a4.951 4.951 0 00-.342-1.63l1.919-1.315-1.314-1.84-1.919 1.314a4.956 4.956 0 00-1.394-.906l.434-2.288-2.195-.42-.434 2.287c-.554.023-1.1.138-1.617.342l-1.314-1.919-1.84 1.314 1.314 1.919c-.378.41-.685.881-.907 1.393l-2.3-.42zm6.743 0a2.47 2.47 0 012.366 3.457 2.444 2.444 0 01-2.142 1.459 2.471 2.471 0 01-2.366-3.457 2.443 2.443 0 012.142-1.486v.027z"
      />
    </svg>
  );
}

export default Coding();
