import React, { useEffect, useState, Fragment, ReactElement } from 'react';
import GlobalPortal from '../ui/GlobalPortal';
import CoursePopup from './popups/CoursePopup';
import { courses, computedWindowQuery } from '../../utils';

export interface Course {
  title: string;
  icon: ReactElement;
  forWhom: Array<string>;
  skills: Array<string>;
  lessons: Array<string>;
  courseTime: Array<string>;
  documents: string;
  price: string;
  next: string;
}

function OurCourses() {
  const [course, toggleCourse] = useState<Course>(courses[0]);
  const [isOpenCourse, toggleOpeningCourse] = useState(false);
  const [isMobile, toggleMobile] = useState(false);

  useEffect(() => {
    toggleMobile(computedWindowQuery(1024));
    window.addEventListener('resize', () => {
      toggleMobile(computedWindowQuery(1024));
    });
  }, []);

  const openCourse = (course: Course) => {
    toggleOpeningCourse(true);
    toggleCourse(course);
  };

  const renderSkills = (skills: Array<string>) => {
    return skills.map((skill) => {
      return (
        <div key={skill} className="our-course-skill">
          <p className="our-course-skill__text">
            <i className="fas fa-bolt" />
            {skill}
          </p>
        </div>
      );
    });
  };

  const renderDescription = (forWhom: Array<string>) => {
    return forWhom.map((item) => {
      return (
        <p key={item} className="our-course__description">
          {item}
        </p>
      );
    });
  };

  const renderMobileCourses = () => {
    return courses.map((course: Course) => {
      const { title, icon, forWhom, skills } = course;
      return (
        <div className="our-course" key={title}>
          {icon}
          <div className="our-course-wrapper">
            <h3 className="our-course__title">{title}</h3>
          </div>
          <div className="our-course-wrapper">
            <div className="our-course-whom">
              <i className="fas fa-user fa-2x" />
              <h4 className="our-course-whom__title">Для кого?</h4>
            </div>
            <div className="our-course-descriptions">{renderDescription(forWhom)}</div>
            <div className="our-course-whom">
              <i className="fas fa-user-graduate fa-2x" />
              <h4 className="our-course-whom__title">Чему научим?</h4>
            </div>
            <div className="our-course-skills">{renderSkills(skills)}</div>
            <p className="our-course-skill__more-info" onClick={() => openCourse(course)}>
              Подробнее
            </p>
          </div>
        </div>
      );
    });
  };

  const renderRowDesktop = (row: Array<Course>) => {
    let outputLayout: any = [];
    row.forEach((course: Course) => {
      const { icon, title } = course;
      outputLayout.push(
        <span onClick={() => openCourse(course)} key={title + Math.random()}>
          {icon}
        </span>
      );
    });

    row.forEach((course: Course) => {
      const { title, forWhom, skills } = course;
      outputLayout.push(
        <Fragment key={title + Math.random()}>
          <div className="our-course__title">{title}</div>
          <div className="our-course-whom">
            <i className="fas fa-user fa-2x" />
            <h4 className="our-course-whom__title">Для кого?</h4>
          </div>
          <div className="our-course-skills">{renderDescription(forWhom)}</div>
          <div className="our-course-whom">
            <i className="fas fa-user-graduate fa-2x" />
            <h4 className="our-course-whom__title">Чему научим?</h4>
          </div>
          <div className="our-course-skills">{renderSkills(skills)}</div>
          <div>
            <p className="our-course-skill__more-info" onClick={() => openCourse(course)}>
              Подробнее
            </p>
          </div>
        </Fragment>
      );
    });

    row.forEach((course: Course, index) => {
      const { title } = course;
      outputLayout.push(<div key={title + Math.random()} className={`line-${index}`} />);
    });

    return outputLayout;
  };

  const renderDesktopCourses = () => {
    const firstRow = courses.slice(0, 3);
    const secondRow = courses.slice(3, 6);

    return (
      <div className="our-courses-mobile">
        <div className="our-courses-mobile__row">{renderRowDesktop(firstRow)}</div>
        <div className="our-courses-mobile__row">{renderRowDesktop(secondRow)}</div>
      </div>
    );
  };

  return (
    <ul className="our-courses">
      {isMobile ? renderDesktopCourses() : renderMobileCourses()}
      {isOpenCourse && (
        <GlobalPortal close={() => toggleOpeningCourse(false)}>
          <CoursePopup
            course={course}
            close={() => toggleOpeningCourse(false)}
            renderDescription={renderDescription}
            renderSkills={renderSkills}
          />
        </GlobalPortal>
      )}
    </ul>
  );
}

export default OurCourses;
