import React from 'react';

function Laptop() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="95" height="57" fill="none" viewBox="0 0 95 57">
      <path
        fill="#2996CC"
        d="M87.178 49.701c.023.424-.043.849-.192 1.236a2.735 2.735 0 01-1.031 1.298 2.643 2.643 0 01-1.479.447H10.524a2.644 2.644 0 01-1.472-.442 2.75 2.75 0 01-1.038-1.303A3.003 3.003 0 017.82 49.7l2.357-46.329c.073-1.448 1.326-2.616 2.798-2.616h69.053c1.474 0 2.728 1.177 2.8 2.616l2.35 46.329z"
      />
      <path
        fill="#A1CFE6"
        d="M84.499 48.139a.822.822 0 01-.02.286.752.752 0 01-.115.254.624.624 0 01-.192.178.53.53 0 01-.236.073H11.06a.53.53 0 01-.236-.073.624.624 0 01-.192-.178.752.752 0 01-.115-.254.822.822 0 01-.02-.286l.546-11.05.5-10.388L12.675 3.75a.799.799 0 01.183-.488.583.583 0 01.415-.213H81.783a.614.614 0 01.369.238.818.818 0 01.16.457v.194l.345 6.974.018.4.067 1.372.463 9.354.29 5.856.193 3.767.81 16.479z"
      />
      <path
        fill="#000"
        d="M82.322 3.952H13.897a.585.585 0 00-.417.212.801.801 0 00-.184.488c-.348 7.147-.7 14.338-1.056 21.573-.247 5.034-.497 10.089-.75 15.166-.098 2.03-.426 4.31 0 6.259.095.438.2.868.305 1.286h-.733a.53.53 0 01-.237-.073.624.624 0 01-.193-.18.752.752 0 01-.115-.254.825.825 0 01-.018-.288l2.187-44.39a.8.8 0 01.184-.487.585.585 0 01.416-.213H81.787a.611.611 0 01.368.238.815.815 0 01.16.457l.007.206z"
      />
      <path
        fill="#0F6B99"
        d="M94.592 50.934c.283 0 .483.533.38 1.036-.437 2.142-1.25 4.14-2.284 4.14H2.312c-1.041 0-1.847-1.998-2.285-4.14-.102-.503.098-1.036.38-1.036h94.185z"
      />
      <path
        fill="#000"
        d="M81.126 36.736l-.103-2.06-.157-3.237-.25-5.161-.038-.757v-.22l-.063-1.307-.125-2.54v-.105a.45.45 0 00-.088-.252.338.338 0 00-.202-.13H60.471l-.263-5.342-.04-.809v-.235l-.067-1.398-.168-2.71v-.115a.479.479 0 00-.093-.27.36.36 0 00-.217-.14H19.226a.347.347 0 00-.245.128.475.475 0 00-.108.288l-.14 2.828-.508 10.684L17.922 30l-.322 6.515a.483.483 0 00.079.32c.031.044.07.08.113.105.044.026.092.04.14.043h23.27l-.087 1.739-.05 1.01-.3 6.09a.45.45 0 00.072.299.347.347 0 00.105.099c.04.024.085.038.13.041h40.194a.291.291 0 00.13-.04.343.343 0 00.106-.1.45.45 0 00.072-.299l-.448-9.086z"
        opacity="0.5"
      />
      <path
        fill="#E6E6E6"
        d="M80.605 44.801a.45.45 0 01-.072.3.342.342 0 01-.105.098.291.291 0 01-.13.041H40.106a.292.292 0 01-.13-.041.344.344 0 01-.105-.1.455.455 0 01-.073-.298l.3-6.09.05-1.01.233-4.708.5-10.005.13-2.649a.442.442 0 01.102-.268.323.323 0 01.229-.117H79.109c.078.015.15.061.203.131a.45.45 0 01.087.251v.106l.125 2.54.063 1.306v.221l.037.756.25 5.162.158 3.237.103 2.06.47 9.078z"
      />
      <path
        fill="#FFB062"
        d="M79.512 43.456a.352.352 0 01-.026.122.308.308 0 01-.065.1.256.256 0 01-.093.06.228.228 0 01-.106.013H41.277a.228.228 0 01-.106-.012.258.258 0 01-.094-.061.31.31 0 01-.066-.1.356.356 0 01-.027-.122l.286-4.266.265-4 .46-7.02.123-1.854a.357.357 0 01.113-.205.264.264 0 01.2-.066h35.667a.259.259 0 01.177.077c.05.049.084.116.096.19v.077l.118 1.766.06.915v.153l.035.53.25 3.613.15 2.26.098 1.45.43 6.38z"
      />
      <path
        fill="#FAFAFA"
        d="M78.806 23.503H41.597a.158.158 0 01-.12-.059.22.22 0 01-.05-.142.22.22 0 01.05-.141.158.158 0 01.12-.059h37.21c.045 0 .088.021.12.059.032.037.05.088.05.141a.219.219 0 01-.05.142.158.158 0 01-.12.059zM79.004 24.577H41.4a.158.158 0 01-.12-.059.22.22 0 01-.05-.142.22.22 0 01.05-.141.158.158 0 01.12-.059h37.604c.045 0 .088.022.12.059a.22.22 0 01.05.142.22.22 0 01-.05.141.158.158 0 01-.12.059zM76.778 20.904h-34.26c-.308 0-.558.294-.558.656v.006c0 .363.25.657.558.657h34.26c.308 0 .557-.294.557-.657v-.006c0-.362-.25-.656-.557-.656z"
      />
      <path
        fill="#000"
        d="M61.607 37.23a.487.487 0 01-.078.32.37.37 0 01-.113.107.313.313 0 01-.14.044h-21.13l.233-4.708.5-10.005.13-2.649a.442.442 0 01.102-.268.323.323 0 01.229-.117H60.76l.047.968.048.947.018.374.052 1.08.053 1.071.045.927.037.745.075 1.471.47 9.693z"
        opacity="0.5"
      />
      <path
        fill="#E6E6E6"
        d="M60.248 35.485a.485.485 0 01-.078.319.37.37 0 01-.113.105.311.311 0 01-.139.044H16.957a.312.312 0 01-.14-.043.366.366 0 01-.114-.105.441.441 0 01-.068-.15.488.488 0 01-.011-.17l.323-6.515.303-6.124.525-10.7.14-2.827a.474.474 0 01.108-.288.347.347 0 01.245-.127H58.65a.36.36 0 01.216.14c.057.074.09.17.094.27v.114l.135 2.713.068 1.398v.235l.04.81.272 5.514.17 3.452.116 2.224.487 9.71z"
      />
      <path
        fill="#FFB062"
        d="M59.077 34.055c0 .046-.009.091-.025.133a.334.334 0 01-.069.11.276.276 0 01-.1.069.243.243 0 01-.116.015h-40.56a.243.243 0 01-.116-.014.276.276 0 01-.101-.068.336.336 0 01-.07-.11.384.384 0 01-.025-.135l.303-4.561.277-4.288.5-7.491.133-1.98a.383.383 0 01.12-.222.283.283 0 01.213-.073H57.57c.07.001.137.032.19.085.054.054.09.128.102.21v.079l.128 1.9.065.98v.165l.038.565.25 3.86.16 2.417.102 1.55.473 6.804z"
      />
      <path
        fill="#FAFAFA"
        d="M58.322 12.712H18.538a.158.158 0 01-.12-.059.22.22 0 01-.05-.141.22.22 0 01.05-.142.158.158 0 01.12-.058H58.32c.046 0 .089.02.12.058a.22.22 0 01.05.142.22.22 0 01-.05.141.158.158 0 01-.12.059zM58.534 13.86H18.338a.158.158 0 01-.12-.059.22.22 0 01-.05-.141.22.22 0 01.05-.142.158.158 0 01.12-.059h40.196c.045 0 .089.022.12.06a.22.22 0 01.05.14.22.22 0 01-.05.142.158.158 0 01-.12.059zM56.152 9.949h-36.62c-.33 0-.596.313-.596.7v.009c0 .387.266.7.595.7h36.621c.329 0 .596-.313.596-.7v-.009c0-.387-.267-.7-.596-.7z"
      />
      <g style={{ mixBlendMode: 'soft-light' }}>
        <path
          style={{ mixBlendMode: 'soft-light' }}
          fill="url(#paint0_linear)"
          d="M79.77 3.057L62.868 48.94h-18.27l17.26-45.882H79.77z"
        />
        <path
          style={{ mixBlendMode: 'soft-light' }}
          fill="url(#paint1_linear)"
          d="M83.688 31.669l-6.257 17.27h-7.927l13.249-36.242.46 9.358.29 5.853.185 3.76z"
        />
      </g>
      <g style={{ mixBlendMode: 'soft-light' }}>
        <path
          style={{ mixBlendMode: 'soft-light' }}
          fill="url(#paint2_linear)"
          d="M39.158 4.028L21.413 49.071h-9.396a.5.5 0 01-.223-.074.593.593 0 01-.178-.173.712.712 0 01-.104-.243.778.778 0 01-.013-.272l.65-10.694.619-10.146L22 4.031h17.154l.003-.003z"
        />
        <path
          style={{ mixBlendMode: 'soft-light' }}
          fill="url(#paint3_linear)"
          d="M52.892 4.031l-17.8 45.043h-7.444L45.44 4.031h7.45z"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="66.571"
          x2="51.929"
          y1="-2.351"
          y2="66.049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="4755.65"
          x2="1387.51"
          y1="54.375"
          y2="7297.96"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="4771.69"
          x2="1579.09"
          y1="10262.6"
          y2="20644.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="5908.84"
          x2="2465.24"
          y1="10715.2"
          y2="20926.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Laptop();
