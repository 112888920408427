import UserPC from '../components/icons/UserPC';
import Design from '../components/icons/Design';
import Coding from '../components/icons/Coding';
import Python from '../components/icons/Python';
import C from '../components/icons/C';
import Unity from '../components/icons/Unity';

export default [
  {
    icon: UserPC,
    title: 'Пользователь ПК',
    forWhom: ['Школьников от 10 до 14 лет', '(начальный уровень)'],
    skills: [
      'Владеть ПК на уровне продвинутого   пользователя',
      'Редактировать изображения, аудио, видео',
      'Создавать анимации, 3D-модели, 2D-игры',
      'Разрабатывать несложные сайты на языках HTML, CSS',
    ],
    lessons: ['2 раза в неделю', 'по 2 академических часа'],
    courseTime: ['Сентябрь-май', '(144 академических часа)'],
    documents: 'Свидетельство установленного образца',
    price: '2950 руб/мес',
    next: 'Можно продолжить обучение на любом курсе программирования или web-разработки ',
  },
  {
    icon: Design,
    title: 'Web-дизайн',
    forWhom: ['Школьников от 12 до 15 лет', '(базовый уровень)'],
    skills: [
      'Использовать графические редакторы и верстать сайты',
      'Создавать сайты-визитки, персональные страницы',
      '“Оживлять” web-страницы с помощью JavaScript',
      'Работать с системами управления контентом (CMS)',
    ],
    lessons: ['2 раза в неделю', 'по 2 академических часа'],
    courseTime: ['Сентябрь-май', '(144 академических часа)'],
    documents: 'По окончании 2 курса - Диплом установленного образца',
    price: '3100 руб/мес',
    next: 'Можно продолжить обучение на курсе web-программирования',
  },
  {
    icon: Coding,
    title: 'Web-программирование',
    forWhom: ['Школьников от 13 до 16 лет', '(после курса по Web-дизайну)'],
    skills: [
      'Разрабатывать сайты и web-приложения на языке PHP',
      'Работать с базами данных, языком запросов SQL',
      'Создавать интернет-магазины, социальные сети',
      'Разрабатывать личные кабинеты (backend) для сайтов',
    ],
    lessons: ['2 раза в неделю', 'по 2 академических часа'],
    courseTime: ['Сентябрь-май', '(144 академических часа)'],
    documents: 'Диплом установленного образца',
    price: '3200 руб/мес',
    next: 'Можно продолжить обучение на любом курсе программирования',
  },
  {
    icon: Python,
    title: 'Программирование на Python',
    forWhom: ['Школьников от 12 до 15 лет', '(базовый уровень)'],
    skills: [
      'Создавать прикладные программы на языке Python',
      'Разрабатывать собственные игры и графику к ним',
      'Работать с данными, файлами и каталогами',
      'Владеть инструментами компьютерного моделирования',
    ],
    lessons: ['2 раза в неделю', 'по 2 академических часа'],
    courseTime: ['Сентябрь-май', '(144 академических часа)'],
    documents: 'Свидетельство установленного образца',
    price: '3100 руб/мес',
    next: 'Можно продолжить обучение на курсе программирования на C/C++',
  },
  {
    icon: C,
    title: 'Программирование на C/C++',
    forWhom: ['Школьников от 15 до 17 лет', '(базовый уровень)'],
    skills: [
      'Разбираться в синтаксисе языков C и C++',
      'Понимать алгоритмы и принципы программирования',
      'Основам объектно-ориентированного программирования',
      'Разрабатывать ПО для учебных и коммерческих задач',
    ],
    lessons: ['2 раза в неделю', 'по 2 академических часа'],
    courseTime: ['Сентябрь-май', '(144 академических часа)'],
    documents: 'Диплом установленного образца',
    price: '3200 руб/мес',
    next: 'Можно продолжить обучение на курсе web-программирования',
  },
  {
    icon: Unity,
    title: 'Разработка игр в среде Unity 3D',
    forWhom: ['Школьников от 12 до 15 лет', '(базовый уровень)'],
    skills: [
      'Придумывать идеи для игровых проектов',
      'Использовать скрипты в управлении объектами',
      'Создавать анимацию с помощью скриптов на языке C#',
      'Реализовывать дизайнерские решения для игр',
    ],
    lessons: ['2 раза в неделю', 'по 2 академических часа'],
    courseTime: ['Сентябрь-май', '(144 академических часа)'],
    documents: 'Именной сертификат об окончании курсов',
    price: '3200 руб/мес',
    next: 'Можно продолжить обучение на любом курсе программирования или web-разработки',
  },
];
