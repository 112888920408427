import React, { useState, useEffect } from 'react';
import { get, isFunction } from 'lodash';
import SelectQuiz from '../../icons/SelectQuiz';
import { courses } from '../../../utils';
import { Course } from '../OurCourses';
import GlobalPortal from '../../ui/GlobalPortal';
import CoursePopup from './CoursePopup';
import ConsultationPopup from './ConsultationPopup';

const coursesUtils: Array<Course> = courses;

type Props = {
  close: any;
};

interface Quiz {
  title: string;
  type: string;
  answers?: Array<Answer>;
  courses?: Array<Course>;
}

interface scopedCourses {
  [key: string]: Quiz;
}

interface Answer {
  answer: string;
  next: Quiz;
}

let userAnswers: any = [];

function QuizPopup(props: Props) {
  const { close } = props;

  useEffect(() => {
    userAnswers = [];
  }, []);

  const getCourses = (key: string) => {
    const scopeCourses: scopedCourses = {
      userPC: {
        type: 'courses',
        title: 'Ваш курс:',
        courses: [coursesUtils[0]],
      },
      web: {
        type: 'courses',
        title: 'Ваш курс:',
        courses: [coursesUtils[1], coursesUtils[2]],
      },
      python: {
        type: 'courses',
        title: 'Ваш курс:',
        courses: [coursesUtils[3]],
      },
      c: {
        type: 'courses',
        title: 'Ваш курс:',
        courses: [coursesUtils[4]],
      },
      unity: {
        type: 'courses',
        title: 'Ваш курс:',
        courses: [coursesUtils[5]],
      },
    };
    return function () {
      return scopeCourses[key];
    };
  };

  const getAge = (): object => {
    return {
      type: 'answers',
      title: 'Сколько вам лет?',
      answers: [
        {
          answer: '10-11',
          next: getCourses('userPC'),
        },
        {
          answer: '12-14',
          next: getLevelPC,
        },
        {
          answer: '15',
          next: getWhatWantLearn,
        },
        {
          answer: '16-17',
          next: getWhatWantLearnWithoutUnity,
        },
      ],
    };
  };

  const getLevelPC = (): object => {
    return {
      type: 'answers',
      title: 'Оцените свой уровень владения ПК',
      answers: [
        {
          answer: 'Начальный',
          next: getCourses('userPC'),
        },
        {
          answer: 'Средний и выше',
          next: getWhatWantLearn,
        },
      ],
    };
  };

  const getWhatWantLearn = (): object => {
    return {
      type: 'answers',
      title: 'Чему вы хотите научиться?',
      answers: [
        {
          answer: 'Создавать и верстать сайты, интернет-магазины и web-приложения',
          next: getCourses('web'),
        },
        {
          answer: 'Разрабатывать ПО под учебные и коммерческие задачи(игры, web-приложения)',
          next: getDevelop,
        },
        {
          answer: 'Придумывать и реализовывать игровые проекты на движках',
          next: getCourses('unity'),
        },
      ],
    };
  };

  const getWhatWantLearnWithoutUnity = (): object => {
    return {
      type: 'answers',
      title: 'Чему вы хотите научиться?',
      answers: [
        {
          answer: 'Создавать и верстать сайты, интернет-магазины и web-приложения',
          next: getCourses('web'),
        },
        {
          answer: 'Разрабатывать ПО под учебные и коммерческие задачи(игры, web-приложения)',
          next: getDevelop,
        },
      ],
    };
  };

  const getDevelop = (): object => {
    return {
      type: 'answers',
      title: 'У вас есть начальные знания по программированию?',
      answers: [
        {
          answer: 'Да',
          next: getCourses('c'),
        },
        {
          answer: 'Нет',
          next: getCourses('python'),
        },
      ],
    };
  };

  const [currentQuiz, toggleQuiz] = useState(getAge());
  // @ts-ignore
  const [nextQuiz, toggleNextQuiz] = useState<Quiz>(getAge().answers[0].next);
  const [isOpenCourse, toggleOpeningCourse] = useState(false);
  const [isOpenConsultation, toggleOpenConsultation] = useState(false);
  const [course, toggleCourse] = useState<Course>(courses[0]);

  const renderAnswers = (answers: Array<Answer>) => {
    return answers.map((elem, index) => {
      const defaultAnswer = index === 0;
      const { answer, next } = elem;
      return (
        <div key={answer} className="quiz-popup__answer">
          <input
            className="custom-radio"
            name="answers"
            type="radio"
            id={`answer-${index}`}
            // @ts-ignore
            onChange={() => toggleNextQuiz(next)}
            defaultChecked={defaultAnswer}
          />
          <label htmlFor={`answer-${index}`}>{answer}</label>
        </div>
      );
    });
  };

  const changeNextAnswer = (quiz: any) => {
    const { type } = quiz;
    if (type === 'answers') {
      const next = get(quiz, 'answers[0].next');
      if (isFunction(next)) {
        toggleNextQuiz(next());
      }
    }
  };

  const changeNextQuiz = (nextQuiz: any) => {
    changeNextAnswer(nextQuiz);
    userAnswers.push(currentQuiz);
    toggleQuiz(nextQuiz);
  };

  const changePrevQuiz = () => {
    if (!userAnswers.length) {
      return close();
    }
    const newQuiz = userAnswers.pop();
    changeNextAnswer(newQuiz);
    toggleQuiz(newQuiz);
  };

  const openCourse = (course: Course) => {
    toggleOpeningCourse(true);
    toggleCourse(course);
  };

  const renderCourses = (courses: Array<Course>) => {
    return courses.map((course) => {
      const { title } = course;
      return (
        <div key={title} className="quiz-popup-body-course">
          <h3 className="quiz-popup-body-course__title">{title}</h3>
          <p className="quiz-popup-body-course__about" onClick={() => openCourse(course)}>
            Подробнее
          </p>
          <button type="button" className="main-button" onClick={() => toggleOpenConsultation(true)}>
            Записаться
          </button>
        </div>
      );
    });
  };

  const renderContent = (quiz: any) => {
    const { answers, type, title, courses } = quiz;
    switch (type) {
      case 'answers': {
        return (
          <div className="quiz-popup-body">
            <h3 className="quiz-popup-body__question">{title}</h3>
            <div className="quiz-popup-body-content">
              {renderAnswers(answers)}
              <div className="quiz-popup-body-buttons">
                <button type="button" className="main-button-white" onClick={() => changePrevQuiz()}>
                  Назад
                </button>
                <button type="button" className="main-button" onClick={() => changeNextQuiz(nextQuiz)}>
                  Далее
                </button>
              </div>
            </div>
          </div>
        );
      }
      case 'courses': {
        return (
          <div className="quiz-popup-body">
            <h3 className="quiz-popup-body__question">{title}</h3>
            {renderCourses(courses)}
          </div>
        );
      }
    }
  };

  const renderSkills = (skills: Array<string>) => {
    return skills.map((skill) => {
      return (
        <div key={skill} className="our-course-skill">
          <p className="our-course-skill__text">
            <i className="fas fa-bolt" />
            {skill}
          </p>
        </div>
      );
    });
  };

  const renderDescription = (forWhom: Array<string>) => {
    return forWhom.map((item) => {
      return (
        <p key={item} className="our-course__description">
          {item}
        </p>
      );
    });
  };

  return (
    <>
      <div className="quiz-popup">
        <i className="far fa-2x fa-times-circle global-portal__close" onClick={close} />
        <div className="quiz-popup-header">
          <h3 className="quiz-popup__title">Выбираем курс для обучения</h3>
          <div className="quiz-popup__icon">
            <SelectQuiz />
          </div>
        </div>
        {renderContent(currentQuiz)}
      </div>
      {isOpenCourse && (
        <GlobalPortal close={() => toggleOpeningCourse(false)}>
          <CoursePopup
            course={course}
            close={() => toggleOpeningCourse(false)}
            renderDescription={renderDescription}
            renderSkills={renderSkills}
          />
        </GlobalPortal>
      )}
      {isOpenConsultation && (
        <GlobalPortal close={() => toggleOpenConsultation(false)}>
          <ConsultationPopup
            close={() => toggleOpenConsultation(false)}
            ymTarget={() => {
              //@ts-ignore
              window.ym(67016755, 'reachGoal', 'quizForm');
            }}
          />
        </GlobalPortal>
      )}
    </>
  );
}

export default QuizPopup;
