import React from 'react';

function Login() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="82" height="82" fill="none">
      <g>
        <path
          fill="url(#paint0_linear)"
          d="M26.586 9.61h39.078v62.78H26.586a2.402 2.402 0 01-2.402-2.402V12.012a2.402 2.402 0 012.402-2.403z"
        />
        <path
          fill="url(#paint1_linear)"
          d="M7.367 33.793H19.38v-2.402a2.402 2.402 0 013.735-2l14.414 9.61a2.403 2.403 0 010 3.998l-14.414 9.61a2.402 2.402 0 01-3.735-2v-2.402H7.367a2.402 2.402 0 01-2.402-2.402v-9.61a2.402 2.402 0 012.402-2.402zM44.4.453a2.402 2.402 0 012.164-.33l28.828 9.61a2.403 2.403 0 011.643 2.279v57.976a2.402 2.402 0 01-1.643 2.28l-28.828 9.609a2.402 2.402 0 01-3.162-2.28V2.403c0-.772.372-1.497.998-1.948zm11.014 42.95a2.402 2.402 0 100-4.805 2.402 2.402 0 000 4.804z"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="44.924"
          x2="44.924"
          y1="72.391"
          y2="9.609"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE59A" />
          <stop offset="1" stopColor="#FFFFD5" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="41" x2="41" y1="82" y2="0" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FD5900" />
          <stop offset="1" stopColor="#FFDE00" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Login();
