import React, { useState } from 'react';
import GlobalPortal from '../ui/GlobalPortal';
import QuizPopup from './popups/QuizPopup';

function CourseHelp() {
  const [openQuiz, toggleQuiz] = useState(false);

  return (
    <div className="course-help">
      <div className="course-help-form">
        <h3 className="course-help-form__title">Не можешь определиться с курсом?</h3>
        <p className="course-help-form__description">Пройди быстрый тест!</p>
        <button className="main-button" type="button" onClick={() => toggleQuiz(true)}>
          Начать тест
        </button>
      </div>
      {openQuiz && (
        <GlobalPortal close={() => toggleQuiz(false)}>
          <QuizPopup close={() => toggleQuiz(false)} />
        </GlobalPortal>
      )}
    </div>
  );
}

export default CourseHelp;
