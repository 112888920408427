import React from 'react';

interface Props {
  close: any;
}

function SuccessPopup(props: Props) {
  const { close } = props;
  return (
    <div className="consultation-popup">
      <i className="far fa-2x fa-times-circle global-portal__close" onClick={close} />
      <div className="success-block">
        <i className="fas fa-5x fa-check-circle success-block__icon" />
        <h3 className="success-block__title">Спасибо!</h3>
        <p className="success-block__text">Мы получили Вашу заявку и в ближайшее время Вам перезвоним!</p>
      </div>
    </div>
  );
}

export default SuccessPopup;
