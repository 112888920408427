import React from 'react';

function LogoMobile() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="66"
      fill="none"
      viewBox="0 0 60 66"
      className="header-block-logo__mobile"
    >
      <path
        fill="#008BD2"
        fillRule="evenodd"
        d="M26.328 0c5.636 0 11.179 1.431 16.105 4.158A33.081 33.081 0 0154.48 15.585a32.88 32.88 0 011.56 32.035 33.042 33.042 0 01-10.88 12.535 33.27 33.27 0 01-32.06 3.103A33.12 33.12 0 010 53.045l.531.319c5.316 5.408 12.72 8.294 20.926 8.294a28.561 28.561 0 0011.066-2.093 28.463 28.463 0 009.383-6.204 28.323 28.323 0 006.23-9.343A28.226 28.226 0 0050.238 33c0-16.094-12.145-28.658-28.308-28.658-8.198 0-16.09 2.9-21.399 8.294L0 12.955a33.053 33.053 0 0111.641-9.546A33.201 33.201 0 0126.328 0zm28.766 30.05a2.98 2.98 0 00-1.65.498 2.947 2.947 0 00-.45 4.55 2.974 2.974 0 003.237.642 2.967 2.967 0 001.333-1.09 2.948 2.948 0 00-.367-3.732 2.977 2.977 0 00-2.096-.869h-.007z"
        clipRule="evenodd"
      />
      <path
        fill="#009FE3"
        fillRule="evenodd"
        d="M21.464 3.85c4.826 0 9.578 1.187 13.831 3.459a29.217 29.217 0 0110.547 9.557 29.024 29.024 0 012.594 27.422 29.148 29.148 0 01-8.568 11.352 29.32 29.32 0 01-12.94 5.97 29.382 29.382 0 01-14.237-.83A29.283 29.283 0 01.54 53.35a23.753 23.753 0 0017.983 2.413 23.696 23.696 0 008.304-4.061 23.585 23.585 0 006.105-6.921l-12.253-7.047a9.453 9.453 0 01-4.575 3.995 9.503 9.503 0 01-6.072.396 9.467 9.467 0 01-5.06-3.366 9.398 9.398 0 010-11.474 9.467 9.467 0 015.06-3.366 9.503 9.503 0 016.072.396 9.453 9.453 0 014.575 3.994l12.253-7.06a23.586 23.586 0 00-6.097-6.918 23.697 23.697 0 00-8.294-4.063 23.754 23.754 0 00-17.972 2.39 29.172 29.172 0 019.552-6.51A29.275 29.275 0 0121.464 3.85zm7.383 26.178a2.98 2.98 0 00-1.65.498 2.96 2.96 0 00-1.264 3.037c.115.573.398 1.1.813 1.514a2.974 2.974 0 003.238.641 2.968 2.968 0 001.333-1.09 2.949 2.949 0 00-.37-3.734 2.977 2.977 0 00-2.1-.866zm8.737 0a2.98 2.98 0 00-1.65.498 2.946 2.946 0 00-.45 4.55 2.975 2.975 0 003.236.642 2.968 2.968 0 001.334-1.09 2.949 2.949 0 00-.37-3.734 2.977 2.977 0 00-2.1-.866zm8.737 0a2.98 2.98 0 00-1.65.498 2.946 2.946 0 00-.45 4.55 2.975 2.975 0 003.237.642 2.968 2.968 0 001.333-1.09c.326-.486.5-1.057.5-1.642a2.951 2.951 0 00-.867-2.068 2.977 2.977 0 00-2.074-.869l-.03-.021z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default LogoMobile;
