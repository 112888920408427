import React from 'react';

function Graduate() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="108" height="70" fill="none">
      <g filter="url(#filter0_d)">
        <path
          fill="#222D48"
          d="M55.27 18.651l-28.89-6.133 3.423 22.035s13.017 10.223 25.45 10.223c12.434 0 25.452-10.223 25.452-10.223l3.451-22.035L55.27 18.65z"
        />
        <path
          fill="#11182E"
          d="M55.27 40.515c-12.434 0-26.102-10.481-26.102-10.481l.648 4.179s13.017 10.223 25.45 10.223c12.434 0 25.451-10.223 25.451-10.223l.648-4.18S67.703 40.516 55.269 40.516z"
          opacity="0.45"
        />
        <path
          fill="#FFB33C"
          d="M55.27 40.87c-12.434 0-26.102-10.482-26.102-10.482l.648 4.18S42.833 44.79 55.266 44.79c12.434 0 25.451-10.224 25.451-10.224l.648-4.179S67.703 40.87 55.269 40.87z"
        />
        <path
          fill="#11182E"
          d="M26.259 11.382l.992 6.645 27.928 11.757 24.456-11.132s-7.672 18.921-24.366 26.124c0 0 13.747 3.828 26.756-7.2C95.032 26.55 90.24 7.246 90.24 7.246l-63.98 4.136z"
          opacity="0.45"
        />
        <path fill="#222D48" d="M55.27 0L6.54 6.157v2.954l48.73 12.54L104 9.112V6.157L55.27 0z" />
        <path fill="#273352" d="M6.54 6.157v2.954l48.73 12.54v-5.45L6.54 6.156z" />
        <path fill="#0A1327" d="M104 6.157v2.954L55.27 21.65V16.2L104 6.156z" />
        <path
          fill="#11182E"
          d="M76.212 11.85c-.93-1.116-10.44-3.085-16.513-4.246-.187-.81-1.946-1.453-4.085-1.453-2.267 0-4.106.712-4.106 1.589s1.839 1.592 4.106 1.592c1.517 0 2.84-.32 3.551-.793 7.923 1.516 15.503 3.253 16.25 3.95.724 1.504.319 20.347-.108 31.921l1.028.038c0-.079.291-7.819.426-15.625.285-15.974-.278-16.648-.549-16.973z"
          opacity="0.4"
        />
        <path
          fill="#FFB33C"
          d="M75.753 44.793l-1.027-.037c.426-11.574.829-30.417.108-31.922-.852-.795-10.639-2.95-19.655-4.583l.183-1.005c4.462.808 19.107 3.55 20.275 4.938.271.322.831.999.545 16.976a972.703 972.703 0 01-.429 15.633z"
        />
        <path
          fill="#FFB33C"
          d="M75.24 44.886c.882 0 1.596-.71 1.596-1.588 0-.878-.714-1.589-1.596-1.589-.881 0-1.596.711-1.596 1.589 0 .877.715 1.588 1.596 1.588z"
        />
        <path
          fill="#FFB33C"
          d="M75.24 44.355s-3.277 0-3.277 12.352c0 0 .768.85 3.277.85 2.51 0 3.277-.85 3.277-.85 0-12.352-3.277-12.352-3.277-12.352z"
        />
        <path
          fill="#FFD38F"
          d="M72.777 57.186s-.25-1.949.199-5.533l-.199 5.533zm1.801.36a40.315 40.315 0 01.145-5.893l-.145 5.893zm1.666-.055c.14-1.943.14-3.894 0-5.838v5.838zm1.546-.314c.2-1.837.155-3.693-.134-5.518l.134 5.518z"
        />
        <path
          fill="#11182E"
          d="M74.493 39.322s.02 2.384.747 2.384 2.078 2.64-.732 2.994c0 0 1.112.134 1.791 2.115 1.13 3.302 1.494 10.357-4.336 9.874l1.048 3.306 9.3-.538-.584-16.944-7.234-3.191z"
          opacity="0.45"
        />
        <path
          fill="#11182E"
          d="M59.564 8.03c-1.727-.333-3.21-.606-4.202-.784l-.183 1.005c1.088.198 2.185.401 3.28.61.563-.23.957-.52 1.105-.83z"
          opacity="0.76"
        />
        <path
          fill="#FFB33C"
          d="M55.27 9.34c2.267 0 4.105-.712 4.105-1.591 0-.88-1.838-1.592-4.106-1.592-2.267 0-4.105.713-4.105 1.592 0 .879 1.838 1.591 4.105 1.591z"
        />
        <path
          fill="#11182E"
          d="M55.27 9.34c2.267 0 4.105-.712 4.105-1.591 0-.88-1.838-1.592-4.106-1.592-2.267 0-4.105.713-4.105 1.592 0 .879 1.838 1.591 4.105 1.591z"
          opacity="0.45"
        />
        <path
          fill="#FFB33C"
          d="M54.677 8.809c1.798 0 3.256-.565 3.256-1.26 0-.697-1.458-1.261-3.256-1.261-1.799 0-3.257.564-3.257 1.26 0 .696 1.458 1.26 3.257 1.26z"
        />
        <path
          fill="#fff"
          d="M53.453 7.783c.804-.154 1.409-.518 1.352-.813-.057-.294-.755-.408-1.559-.254-.804.155-1.41.519-1.352.813.057.295.755.408 1.559.254zm21.334 35.094c.208-.305.26-.63.118-.727-.143-.096-.427.074-.635.38-.208.305-.26.63-.117.727.142.096.427-.074.634-.38zm.807 5.161c.199-1.065-.094-2.012-.653-2.115-.56-.103-1.174.676-1.372 1.74-.199 1.065.094 2.012.653 2.115.56.104 1.174-.676 1.372-1.74z"
          opacity="0.25"
        />
        <path fill="#EDE3C3" d="M34.202 53.649l13.005 6.68C49.658 50.004 54.71 47.11 54.71 47.11l-13.13-6.747" />
        <path
          fill="#EDE3C3"
          d="M43.697 58.409c.213.16 3.093 2.091 4.418 2.323 1.324.233 5.386.851 7.003-3.27 1.616-4.121-1.938-6.64-1.938-6.64s-1.354-1.649-4.213-.824c-2.86.825-5.27 8.411-5.27 8.411z"
        />
        <path
          fill="#EDE3C3"
          d="M48.412 46.954c.146.305 10.943 6.19 10.943 6.19.265.673-3.096 13.36-16 5.027l-2.917-4.882s1.435-7.223 5.05-7.787c3.616-.563 8.314.035 10.43 2.806 2.115 2.77 1.056 5.05 1.056 5.05l-8.562-6.404z"
        />
        <path
          fill="#B8AD8F"
          d="M46.924 49.757c.16.026 2.334-1.005 2.334-1.005s3.25 1.47 3.91 2.06c.66.589.663 2.145.663 2.145l-6.907-3.2z"
        />
        <path
          fill="#E3D2A1"
          d="M52.772 49.99l-13.105-6.556-1.94 6.9 12.947 6.878c-4.016-2.3-2.133-4.301-1.906-6.047.228-1.745 4.004-1.176 4.004-1.176z"
        />
        <path
          fill="#FFF1C9"
          d="M53.97 46.327l-13.536-6.535-24.58-12.065a4.304 4.304 0 00-.535-.29 7.273 7.273 0 00-.805-.351l-.102-.05a7.233 7.233 0 00-5.29.182 7.185 7.185 0 00-3.735 3.73 7.137 7.137 0 00-.166 5.266 7.178 7.178 0 003.495 3.956c.31.16 23.382 12.55 23.382 12.55l14.523 7.608s-2.872-1.742-3.502-4.922c-.63-3.18 1.167-6.012 1.917-6.869.75-.857 3.268-3.601 8.935-2.21z"
        />
        <path
          fill="#9E0826"
          d="M32.094 52.713a7.48 7.48 0 01-.344-.188 7.627 7.627 0 01-3.585-4.55 7.59 7.59 0 01.655-5.744 7.65 7.65 0 014.518-3.634 7.689 7.689 0 015.78.568l-8.305-4.267a7.681 7.681 0 00-10.4 3.232 7.594 7.594 0 001.002 8.47 7.653 7.653 0 002.375 1.841l8.304 4.272z"
        />
        <path
          stroke="#FFFAE8"
          strokeMiterlimit="10"
          strokeWidth="1.85"
          d="M59.355 52.987c0 4.318-3.834 8.942-10.607 7.841-3.668-.604-6.621-4.1-5.401-9.163a7.554 7.554 0 013.408-4.667 7.617 7.617 0 015.725-.91 6.062 6.062 0 013.748 2.71 6.011 6.011 0 01.734 4.552 4.828 4.828 0 01-2.177 2.985c-1.094.67-2.41.88-3.659.584a3.88 3.88 0 01-2.399-1.733 3.847 3.847 0 01-.47-2.914 3.09 3.09 0 011.396-1.914 3.117 3.117 0 012.346-.372c.64.153 1.193.553 1.537 1.112.344.559.451 1.23.298 1.868"
        />
        <path
          fill="#BF0D32"
          d="M24.455 44.102s-1.278 6.003-.19 10.828a13.067 13.067 0 011.512-2.925 23.886 23.886 0 002.402 3.125s-1.302-9.44-.633-11.995l-3.09.967z"
        />
        <path
          fill="#BF0D32"
          d="M22.103 42.145s-3.723 4.887-4.788 9.619a13.735 13.735 0 012.626-2.033c.178 1.257.466 2.497.858 3.706 0 0 2.833-8.94 4.535-10.973l-3.23-.32z"
        />
        <path
          fill="#11182E"
          d="M31.342 41.236c.047-.532.461-1.072.374-1.575-.088-.502-.669-.888-.876-1.353-.207-.465-.134-1.162-.467-1.548-.332-.386-1.018-.433-1.459-.732-.44-.299-.685-.93-1.181-1.11-.497-.18-1.089.137-1.623.088-.534-.05-1.077-.46-1.581-.372-.505.087-.893.665-1.36.871-.467.207-1.167.134-1.556.465-.388.331-.434 1.014-.735 1.452-.3.439-.934.683-1.114 1.177-.181.493.137 1.083.087 1.614-.05.532-.461 1.072-.373 1.574.087.503.668.89.875 1.354.207.465.134 1.162.467 1.548.332.386 1.018.433 1.459.732.44.299.686.926 1.182 1.11.496.182 1.088-.137 1.622-.088.534.05 1.077.459 1.582.372.504-.087.892-.665 1.36-.871.466-.207 1.167-.134 1.555-.465.388-.331.434-1.014.735-1.452.3-.439.934-.683 1.115-1.177.18-.493-.15-1.08-.088-1.614z"
          opacity="0.5"
        />
        <path
          fill="#FF9215"
          d="M31 40.826c.047-.523.456-1.057.371-1.55-.084-.495-.66-.872-.875-1.337-.216-.465-.131-1.133-.461-1.528s-1.004-.427-1.427-.723c-.423-.296-.674-.915-1.167-1.092-.493-.177-1.074.134-1.6.087-.524-.046-1.061-.453-1.558-.368-.495.084-.875.656-1.342.87-.467.216-1.138.131-1.535.46-.396.328-.429.999-.726 1.42-.298.421-.92.67-1.097 1.162-.178.49.134 1.069.087 1.591-.047.523-.455 1.057-.37 1.551.084.494.66.871.875 1.336.216.465.131 1.133.461 1.528s1.004.427 1.427.723c.423.296.674.915 1.167 1.092.493.177 1.074-.134 1.6-.087.524.046 1.061.453 1.557.369.496-.084.876-.657 1.343-.872.466-.215 1.138-.13 1.535-.458.396-.329.428-1 .726-1.42.298-.422.92-.672 1.097-1.162.178-.491-.134-1.07-.087-1.592z"
        />
        <path
          fill="#11182E"
          d="M29.804 40.726c.221-2.456-1.6-4.624-4.067-4.844-2.467-.22-4.646 1.593-4.867 4.049-.22 2.455 1.6 4.624 4.068 4.844 2.467.22 4.646-1.593 4.866-4.049z"
          opacity="0.25"
        />
        <path
          fill="#FDAC39"
          d="M29.651 40.556c.213-2.37-1.545-4.464-3.927-4.676-2.382-.212-4.486 1.538-4.7 3.909-.212 2.37 1.546 4.464 3.928 4.676 2.382.212 4.486-1.538 4.699-3.909z"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          width="112"
          height="78"
          x="-4"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default Graduate();
