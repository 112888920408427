import React, { ReactElement } from 'react';
import Graduate from '../icons/Graduate';
import Laptop from '../icons/Laptop';
import License from '../icons/License';
import Lists from '../icons/Lists';
import Pig from '../icons/Pig';
import Laptop2 from '../icons/Laptop2';

interface Plus {
  icon: ReactElement;
  title: string;
  description: string;
}

function WhyWeAre() {
  const pluses = [
    {
      icon: Graduate,
      title: 'Комплексный подход',
      description:
        'Мы превращаем увлечение программированием в профессию, направляя интересы в нужное русло. Ребята получают необходимую теоретическую и практическую базу для последующего поступления в вуз и успешной работы',
    },
    {
      icon: Laptop,
      title: 'Меньше теории, больше практики',
      description:
        'Практика составляет 70-80% занятий, потому что только так возможно эффективное усвоение материала. Учащиеся сами пишут игры и программы, разрабатывают сайты и приложения - создают свое первое портфолио',
    },
    {
      icon: License,
      title: 'Лицензия от Министерства образования ',
      description:
        'Программы отвечают образовательным стандартам, а учебные классы оборудованы в соответствии с требованиями пожарной безопасности и СанПин',
    },
    {
      icon: Lists,
      title: 'Успехи в учебе и поступлении',
      description:
        'Мы помогаем ребятам в поступлении в вузы и участии в международных олимпиадах и конкурсах, разбираем задачи по ЕГЭ и вместе проходим школьные тесты',
    },
    {
      icon: Pig,
      title: 'Экономия',
      description:
        'Возможность вычета НДФЛ и использования материнского капитала в счет обучения детей. Программа лояльности для постоянных клиентов, скидки на обучение и возможность получения гранта',
    },
    {
      icon: Laptop2,
      title: 'Офлайн и онлайн',
      description:
        'Обучайтесь в удобном для вас формате - живой групповой урок в оборудованном кабинете или онлайн-конференция из любой точки мира с возможностью просмотра записей',
    },
  ];

  const renderPlus = (plus: Plus) => {
    const { title, description, icon } = plus;
    return (
      <div className="why-we-are-plus">
        <div className="why-we-are-plus-wrapper">
          <h3 className="why-we-are-plus__title">{title}</h3>
          <div className="why-we-are-plus__icon">{icon}</div>
        </div>
        <div className="why-we-are-plus-descriptions">
          <p className="why-we-are-plus__description">{description}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="why-we-are">
      <div className="why-we-are-row">
        {renderPlus(pluses[0])}
        {renderPlus(pluses[1])}
      </div>
      <div className="why-we-are-row">
        {renderPlus(pluses[2])}
        <div className="why-we-are-plus why-we-are-row-main">
          <h3 className="why-we-are-row__main-title">Почему мы?</h3>
        </div>
        {renderPlus(pluses[3])}
      </div>
      <div className="why-we-are-row">
        {renderPlus(pluses[4])}
        {renderPlus(pluses[5])}
      </div>
    </div>
  );
}

export default WhyWeAre;
